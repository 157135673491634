
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.titleIcon {
  display: block;
  margin: 0 auto 16px;
}


.title {
  padding-bottom: 8px;
}

.icon {
  display: block;
  margin: 0 auto 16px;
}

.stickyHeader {
  position: sticky;
  top: -1px; // has to be -1 for the intersection observer to kick in
  background-color: Color.$snow;
  width: calc(100% + 2 * Var.$modal-body-padding);
  margin-left: -1 * Var.$modal-body-padding;
  padding: 8px Var.$modal-body-padding 12px;
  z-index: 2;

  &::before {
    transition: box-shadow 300ms;
    box-shadow: none;
    content: '';
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: -24px;
    right: -24px;
    height: 1px;
  }

  @include Media.medium {
    width: calc(100% + 2 * Var.$modal-body-desktop-padding);
    margin-left: -1 * Var.$modal-body-desktop-padding;
    padding: 8px Var.$modal-body-desktop-padding 12px;
  }

  &.stuck::before {
    box-shadow: 0 0 5px rgb(0, 0, 0);
  }
}

.stickyMask {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  background-color: Color.$snow;
}

.stickyContent {
  position: relative;
  z-index: 1;
}

    