
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.container {
  background-color: Color.$ivory;
  border: 1px solid Color.$smoke;
  padding: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include Media.medium {
    flex-direction: row;
  }
}

.text {
  ul {
    margin: 4px 0;
    padding: 0;
    list-style: none;

    li::before {
      content: '';
      display: inline-block;
      background-color: Color.$eclipse;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-right: 4px;
    }
  }
}

.graphic {
  margin: 0 0 8px;

  @include Media.medium {
    margin: 0 16px 0 0;
    align-self: flex-start;
  }
}

.hideBannerMobile .graphic {
  @include Media.lt-medium {
    display: none;
  }
}

    