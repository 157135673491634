
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.tabs {
  @include Media.lt-medium {
    &.active {
      display: none;
    }
  }

  @include Media.medium {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid Color.$gainsboro;
  }
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid Color.$gainsboro;
  padding-bottom: 16px;

  @include Media.medium {
    display: none;
  }

  &.hasSubtitle {
    margin-top: -8px;
    padding-bottom: 8px;
  }
}

.backButton {
  justify-self: flex-start;
}

.titles {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
}

.container {
  padding-top: 16px;
}

.spacer {
  width: 24px;
}

    