
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'


.extraText {
  margin-top: 12px;
}

.button {
  margin-top: 18px;
  max-width: 320px;
}

    