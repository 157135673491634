
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.inviteVendors {
  max-width: 560px;
  margin: 0 auto;

  h2 {
    margin: 0 0 16px;
    text-align: center;
  }

  p {
    margin: 16px 0;
  }

  button {
    margin-top: 32px;
  }

  ul {
    margin-top: 8px;
    padding-left: 6px;
  }

  li {
    line-height: 1;
    color: Color.$slate;
    list-style: none;
  }

  li::before {
    content: '•';
    display: inline-block;
    margin-right: 6px;
  }

  small {
    line-height: 1;
  }
}

.detailsHeader {
  margin-top: 32px;
  text-align: center;
}

.buttonGroup {
  margin-top: 32px;

  button {
    margin-top: 8px;
  }
}

.illustration {
  margin: 0 auto 16px;
  display: block;
}

.video {
  max-width: 480px;
  margin: 0 auto;
}

.incentive {
  margin: 16px 0 32px;
}

.lowerFormHeader {
  margin: 32px 0 16px;
}

.submitting {
  position: fixed;
  top: 0;
  left: Var.$sidenav-width;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: fade-out(Color.$snow, 0.34);
  z-index: 2;

  @include Media.lt-medium {
    left: 0;
    bottom: Var.$mobilebottomnav-height;
  }
}

    