
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.description {
  margin-bottom: 24px;
}

.searchBar {
  margin: 16px 0 8px;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 8px;

  @include Media.lt-medium {
    padding-bottom: 8px;
  }
}

.entry {
  border-bottom: 1px solid Color.$gainsboro;
  padding: 12px 0;
  display: flex;
  flex-direction: column;
  gap: 2px;
  cursor: pointer;

  .entryName {
    color: Color.$surfie;
    display: inline-flex;
    align-items: center;
  }

  .badge {
    margin-left: 4px;
  }

  .metadata {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }

  &:last-child {
    border-bottom: 0;
  }

  &:hover {
    background-color: Color.$cloud;
  }

  .userTag{
    margin-left: 4px;
    height: 20px;
    min-width: 1px;
  }
}
    