
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.container {
  max-width: 416px;
}

.errorIcon {
  width: 210px;
  height: 156px;
}

.errorHeader {
  margin: 32px 0 16px;
}

    