
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.pagination {
  display: flex;
  align-items: center;

  @include Media.lt-small {
    width: 100%;
  }

  > div {
    margin: 0 8px;
  }

  > div:last-child {
    margin-right: 0;
  }

  > div:first-child {
    margin-left: 0;
  }
}

.details {
  margin-right: 16px;
  color: Color.$eclipse;

  @include Media.lt-small {
    display: none;
  }
}

.pages {
  display: flex;

  @include Media.lt-small {
    display: none;
  }

  > div {
    margin: 0 8px;
  }

  > div:last-child {
    margin-right: 0;
  }

  > div:first-child {
    margin-left: 0;
  }
}

.simpleText {
  white-space: nowrap;

  @include Media.lt-small {
    display: none;
  }
}

.pageNumber:not(.currentPage) {
  color: Color.$surfie;
  cursor: pointer;
}

.button {
  width: 40px;
  height: 40px;
  background: Color.$snow;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  @include Media.lt-small {
    flex-basis: 50%;
    height: 48px;
  }

  svg {
    color: Color.$surfie;
  }
}

.button:not(.disabled) {
  &:hover {
    background: Color.$cloud;
  }

  &:active {
    background: Color.$smoke;
  }
}

.button.left {
  @include Media.lt-small {
    margin-left: 0;
  }
}

.button.disabled {
  background: Color.$gainsboro;
  box-shadow: none;

  svg {
    color: Color.$slate;
  }
}

    