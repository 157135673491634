
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.footer {
  width: 100%;
  border-top: 0.5px solid Color.$gainsboro;
  background: rgba(250, 250, 250, 0.80);
  backdrop-filter: blur(12px);
  padding: 16px;
}

.content {
  margin: 0 auto;
  max-width: 960px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.progressBar {
  width: 100%;
  height: 8px;
  border-radius: 6px;
  background-color: Color.$gainsboro;
  position: relative;
  overflow: hidden;

  .innerProgressBar {
    min-width: 1.25%;
    height: 8px;
    border-radius: 6px;
    background-color: Color.$sea;
    transition: width 350ms cubic-bezier(0.25, 1, 0.5, 1);
    overflow: hidden;
  }

  .gradientOverlay {
    height: 8px;
    background: linear-gradient(to left , rgba(69, 221, 203, 0) 0%, rgba(69, 221, 203, 1) 50%, rgba(69, 221, 203, 0) 100%);
    transform: translateX(-100%);
    animation: gradient 3000ms ease infinite;
  }

  &.noAnimation {
    .gradientOverlay {
      animation: none;
    }
  }
}

@keyframes gradient {
	0% {
    transform: translateX(-100%);
	}

	100% {
    transform: translateX(100%);
	}
}

    