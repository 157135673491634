
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.input {
  margin-top: 8px;
}

.row {
  @include Media.medium {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.selectWrapper {
  @include Media.medium {
    max-width: 240px;
    flex-grow: 1;
  }
}

.removeButton {
  @include Media.lt-medium {
    margin-bottom: 16px;
  }
}

    