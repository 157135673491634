
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

@use 'sass:color';

.errorView {
  display: flex;
  flex: 1;
  align-items: center;
  margin-left: 8px;
  color: Color.$punch;
}

.noteRow {
  background-color: color.adjust(Color.$jaffa, $alpha: -0.95);
  border-bottom: 1px solid Color.$gainsboro;
}

    