
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.section {
  max-width: 560px;
}

.alertBar {
  margin: 16px 0 0;
}

.scheduleDescription {
  display: flex;
  align-items: center;

  svg {
    color: Color.$surfie;
    margin-right: 8px;
  }

  &.disabled {
    svg {
      color: Color.$punch;
    }
  }
}

.toast {
  display: flex;
  align-items: center;

  svg {
    fill: Color.$snow;
    margin-right: 10px;
  }
}

.topLabel {
  margin-top: 12px;
  margin-bottom: 16px;

  &:first-child {
    margin-top: 0;
  }
}

.statementSend {
  padding-left: 26px;
  line-height: 1.33333333;
}

.selectRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  .select {
    flex-basis: 50%;
  }

  @include Media.lt-medium {
    display: block;

    .select:last-child {
      margin-top: 16px;
    }
  }
}

    