
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

@use 'sass:color';

.title {
  margin: 16px 0 8px;
}

.select {
  width: 100%;
  display: inline-block;
  margin-right: 16px;
  vertical-align: middle;

  @include Media.lt-small {
    width: 100%;
    margin-right: 0;
  }
}

.option {
  color: Color.$eclipse;
  padding: 5px 0 5px 16px;
  display: flex;
  align-items: center;

  @include Media.lt-medium {
    flex-wrap: wrap;
    padding: 10px 16px 0;
  }
}

.optionInformation {
  flex: 1 1;
  min-width: 0;

  @include Media.lt-large {
    flex-basis: 100%;
  }
}

.optionLabel {
  display: flex;
  align-items: center;
  padding: 5px 0;
  cursor: pointer;
  flex: 1 1;

  &.hasDescription {
    padding: 10px 0 0;
  }

  > svg {
    margin-right: 8px;
  }

  .name {
    @include Mixin.text-ellipsis();
    margin-right: 8px;
    flex-shrink: 99;
  }
}

.optionDescription {
  display: block;
  flex-basis: 100%;
  margin-top: 2px;
  margin-left: 22px;
  color: Color.$slate;
  padding-right: 8px;
}

.tagCount {
  color: Color.$slate;
}

@include Media.supports-hover {
  .optionInformation:hover + .buttons,
  .option.isFocused:not(.isHovered) {
    .showButton {
      color: Color.$surfie;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), color.adjust(Color.$surfie, $alpha: -0.9);

      &.active {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), Color.$surfie;
        color: Color.$snow;
      }
    }
  }
}

.buttons {
  display: flex;
  justify-self: flex-end;
  align-self: flex-start;
  flex-shrink: 0;

  @include Media.lt-small {
    flex-basis: 100%;
    padding-left: 8px;
  }
}

.button {
  border: 0;
  padding: 5px 16px;
  border-radius: 28px;
  background-color: transparent;
}

.buttonWrapper {
  padding: 8px 0;

  &:last-child {
    padding-right: 16px;
  }
}

.showButton {
  color: Color.$surfie;

  @include Media.supports-hover {
    &:hover {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), color.adjust(Color.$surfie, $alpha: -0.9);
      color: Color.$surfie;
    }
  }

  &.active {
    background: Color.$surfie;
    color: Color.$snow;

    @include Media.supports-hover {
      &:hover {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), Color.$surfie;
        color: Color.$snow;
      }
    }

    &:active {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), Color.$surfie;
      color: Color.$snow;
    }
  }

  &:active {
    background: linear-gradient(0deg, rgba(136, 100, 100, 0.2), rgba(0, 0, 0, 0.2)), color.adjust(Color.$surfie, $alpha: -0.9);
    color: Color.$surfie;
  }
}

.hideButton {
  color: Color.$punch;

  @include Media.supports-hover {
    &:hover {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), color.adjust(Color.$punch, $alpha: -0.9);
      color: Color.$punch;
    }
  }

  &.active {
    background: Color.$punch;
    color: Color.$snow;

    @include Media.supports-hover {
      &:hover {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), Color.$punch;
        color: Color.$snow;
      }
    }

    &:active {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), Color.$punch;
      color: Color.$snow;
    }
  }

  &:active {
    background: linear-gradient(0deg, rgba(136, 100, 100, 0.2), rgba(0, 0, 0, 0.2)), color.adjust(Color.$punch, $alpha: -0.9);
    color: Color.$punch;
  }
}

.multiValuePill {
  line-height: 20px;

  &:not(:last-child) {
    margin-right: 6px;
  }
}

.tagFilterSelect {
  :global(.react-select__value-container) {
    flex-wrap: nowrap;
    mask-image: linear-gradient(90deg, rgba(0, 0, 0, 1) 95%, transparent 100%);
    height: 30px;
  }

  :global(.react-select__menu-list) {
    padding: 8px 0 16px;
  }
}

.innerOption {
  font-weight: bold;
}

    