
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.section {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 560px;
}

.frequencies {
  display: flex;
  flex-direction: column;
  gap: 8x;
}

.frequency {
  display: inline-block;
}

    