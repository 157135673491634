
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.wizard {
  overflow: hidden;
  position: relative;
  // A height here is necessary to prevent a layout bug on Android devices that hides
  // part of the login form
  height: 100px;
  transition: height 100ms;
}

    