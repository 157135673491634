
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.warningText {
  margin: 2px 0;
  min-height: 18px;
  text-align: center;
  color: Color.$punch;
  font-weight: bold;
}

    