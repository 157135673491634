
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.screen {
  text-align: center;

  ul {
    padding-left: 12px;
  }

  li {
    list-style-type: '•';
    padding-left: 6px;
  }
}

.logo {
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
}

.select {
  width: 130px;
  margin: 0 auto;

  :global(.react-select__control) {
    border: unset;
  }

  :global(.react-select__indicator-separator) {
    display: none;
  }
}

.option {
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
    flex-shrink: 0;
  }
}

:global(.react-select__menu-list) {
  .option svg {
    display: none;
  }
}

.message {
  margin: 30px auto 0;
  max-width: 560px;
  text-align: left;
}

    