
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

$header-gap: 24px;
$company-logo-height: 96px;
$transition-duration: 200ms;
$scrolled-logo-dimension: 40px;

.header {
  padding: 84px 16px 16px;
  @include Media.medium {
    padding: 40px 16px 16px;
    height: 278px;
  }
  display: flex;
  gap: $header-gap;
  align-items: center;
  flex-direction: column;
  background-color: Color.$surfie;
  color: Color.$snow;
  position: sticky;
  top: 0;
  z-index: Var.$nav-zindex;
  height: 348px;
  transition: height $transition-duration linear;

  &.noTabs {
    height: 288px;

    @include Media.medium {
      height: 208px;
    }

  }
}

.animationHolder {
  top: 0;
  max-width: 960px;
  margin: 0 auto;
  position: relative;
  width: 100%;
  transition: all $transition-duration linear;
}

.header.scrolled {
    height: 196px;

    .animationHolder {
      top: -16px;
    }

    .companyLogo {
      left: 16px;
      width: $scrolled-logo-dimension !important;
      height: $scrolled-logo-dimension !important;
    }

    .headline {
      top: -12px;
      left: 52px;
      transform: none;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: calc(100% - 32px - $scrolled-logo-dimension);
      text-align: left;
      height: 64px;
      display: flex;
      align-items: center;
    }

    @include Media.medium {
      height: 146px;

      .companyLogo {
        left: 20px;
      }

      .animationHolder {
        top: -24px;
      }

      .headline {
        height: auto;
        display: block;
        left: 60px;
        top: 2px;
        white-space: nowrap;
        max-width: calc(100vw - (100vw - 960px)/2 - 60px - Var.$sidenav-width - 32px - 15px);
      }

      @media(max-width: 960px) {
        .headline {
          max-width: calc(100vw - 60px - Var.$sidenav-width - 32px - 15px);
        }
      }
    }

    &.noTabs {
      height: 146px;

      @include Media.medium {
        height: 76px;
      }
    }
}

.companyLogo {
  position: absolute;
  transition: all $transition-duration linear;

  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.headline {
  position: absolute;
  transition: all $transition-duration linear;
  width: 100%;
  text-align: center;

  top: calc($company-logo-height + $header-gap);

  -webkit-line-clamp: 2;
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @include Media.medium {
    width: auto;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
  }
}

.userMenu {
  @include Mixin.no-print();
  position: absolute;
  top: 8px;
  right: 8px;
  max-width: Var.$sidenav-width;
}

.sections {
  position: absolute;
  bottom: 16px;
  padding: 0 16px;

  @include Media.medium {
    max-width: 960px;
    min-width: 960px;
    padding: 0;
  }

  @media (max-width: 959px) {
    max-width: 100%;
    min-width: 100%;

    &::-webkit-scrollbar {
      display: none;  /* Safari and Chrome */
    }
  }

  width: 100%;
  display: flex;
  overflow: auto;
  gap: 16px;
}

.section {
  flex: 1 0 0;
  height: 44px;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  opacity: 0.66;
  border-radius: 8px;
  transition: background-color 100ms, color 100ms;

  white-space: nowrap;
  padding: 0 8px;

  .count {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: Color.$snow;
    color: Color.$surfie;
    font-weight: 700;
  }

  &.current {
    background-color: Color.$snow;
    color: Color.$surfie;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.20);
    opacity: 1;

    .count {
      background-color: Color.$surfie;
      color: Color.$snow;
    }
  }

  &.complete {
    color: Color.$snow;
    opacity: 1;
    cursor: pointer;

    &:hover {
      background-color: Color.$surfie-dark;
    }

    &:active {
      background-color: Color.$surfie-darker;
    }
  }
}

.buttonBar {
  display: flex;
  flex-direction: column;
  gap:  8px;
}

    