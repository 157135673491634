
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.checkbox {
  margin-right: 8px;
}

.agreementWrapper {
  display: flex;
  margin-top: 6px;
  margin-bottom: 16px;
}

    