
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.section {
  margin: 32px auto 0;
  max-width: 560px;
  width: 100%;

  > * + * {
    margin-top: 8px;
  }
}

.sectionTitle {
  font-weight: bold;
  text-align: center;
}

.additionalContent {
  text-align: center;
}

.alert {
  margin-bottom: 16px;
}

.projectWarning {
  font-style: italic;
  text-align: center;
}

.errorProjectGif {
  width: 220px;
}

.errorProjectDescription {
  text-align: left;

  p:first-child {
    padding-bottom: 16px;
  }
}

    