
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.body {
  padding: 0;
}

.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin: 0 auto;
  color: Color.$snow;
}

    