
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

@import 'partials/normalize';

@font-face {
  font-family: 'PortraitScriptBounceBold';
  src: url('https://static.paywholesail.com/fonts/PortraitScriptBounceBold/PortraitScriptBounceBold.eot');
  src: url('https://static.paywholesail.com/fonts/PortraitScriptBounceBold/PortraitScriptBounceBold.eot') format('embedded-opentype'),
       url('https://static.paywholesail.com/fonts/PortraitScriptBounceBold/PortraitScriptBounceBold.woff2') format('woff2'),
       url('https://static.paywholesail.com/fonts/PortraitScriptBounceBold/PortraitScriptBounceBold.woff') format('woff'),
       url('https://static.paywholesail.com/fonts/PortraitScriptBounceBold/PortraitScriptBounceBold.ttf') format('truetype'),
       url('https://static.paywholesail.com/fonts/PortraitScriptBounceBold/PortraitScriptBounceBold.svg#PortraitScriptBounceBold') format('svg');
}

html,
body {
  background-color: Color.$snow;
  color: Color.$eclipse;
  font-family: Var.$font-family;
  font-size: 14px;
  line-height: calc(22 / 14);

  @include Media.medium {
    min-height: 100vh;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

:focus-visible {
  outline: Color.$surfie-dark solid 2px;
}

h1 {
  font-size: 24px;
  line-height: calc(32 / 24);
  font-weight: 600;
  margin: 0;
}

h2 {
  font-size: 20px;
  line-height: calc(28 / 20);
  font-weight: 600;
  margin: 0;
}

h3 {
  font-size: 16px;
  line-height: calc(24 / 16);
  font-weight: 600;
  margin: 0;
}

p {
  margin: 0 0 0.25em;
}

b {
  font-weight: 700;
}

[role='button'],
button:not(:disabled) {
  cursor: pointer;
}

small {
  font-size: 12px;
  line-height: calc(16 / 12);
}

a,
a:visited,
a:active {
  color: inherit;
}

p a,
p a:visited,
p a:active {
  font-weight: 700;
  color: Color.$surfie;
}

p a:hover {
  color: Color.$surfie-hover;
}

.text-center {
  text-align: center;
}

form {
  margin: 0;
}

.case-upper {
  text-transform: uppercase;
}

.case-lower {
  text-transform: lowercase;
}

.case-capitalize-first::first-letter {
  text-transform: uppercase;
}

.case-lowercase-first::first-letter {
  text-transform: lowercase;
}

.uuid {
  font-feature-settings: 'ss06', 'tnum';
}

.table-currency, .table-number {
  font-feature-settings: 'tnum', 'lnum';
}

.doc-id {
  font-feature-settings: 'ss06' on;
}

[type="search"] {
  background-color: Color.$snow;
}

.custom-unordered-list {
  padding-left: 24px;
  list-style: none;
  display: relative;

  li {
    position: relative;
  }

  li::before {
    content: '';
    position: absolute;
    background-color: Color.$eclipse;
    left: -13.5px;
    top: 10px;
    width: 3px;
    height: 3px;
    border-radius: 50%;
  }
}
    