
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.wrapper {
  @include Media.medium {
    margin: 0 auto;
    width: 560px;
  }
}

.icon {
  margin: 16px auto 24px;
  text-align: center;

  > div {
    position: relative;
    display: inline-block;
  }
}

.iconCheckmark {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: -10px;
  bottom: -10px;

}

.pageHeader {
  text-align: center;
}

.inviteSection {
  border-top: 1px solid Color.$gainsboro;
  padding-top: 24px;
}

.appendix {
  margin: 0;
  padding: 16px 0 0;

  &:first-child {
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
  }
}

.appendix > .content {
  > .header {
    margin: 8px 0 4px;
  }

  > p {
    margin: 16px 0;
  }
}

.confirmationHeader {
  display: flex;
  justify-content: space-between;
}

.button {
  margin: 16px 0;
}

.secondaryButton {
  margin: 8px 0;
}

.resendText {
  text-decoration: underline;
}

// ActivityIndicator is styled assuming it is in a flex container.  This style
// is to recreate that environment.
.resendActivityIndicatorContainer {
  display: flex;
}

    