
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.tooltip {
  background-color: Color.$eclipse;
  color: Color.$snow;
  padding: 8px;
  max-width: 256px;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 400;
  line-height: calc(16 / 12);

  ul {
    margin: 0;
    padding-left: 12px;
  }

  li {
    list-style-type: '•';
    padding-left: 6px;
  }

  a,
  a:visited,
  a:active,
  a:hover {
    color: Color.$snow;
    text-decoration: underline;
  }
}

.container {
  z-index: Var.$tooltip-zindex;
}

.enter {
  opacity: 0;
}

.enterActive {
  opacity: 1;
}

.exit {
  opacity: 1;
}

.exitActive {
  opacity: 0;
  transition: opacity 200ms;
}

.infoButton {
  vertical-align: text-bottom;
}

.focusEl {
  outline: none;
}

.infoTip {
  vertical-align: baseline;
  line-height: 1;
  display: inline;
}

.toolTipContainer {
  border-radius: 2px;
}

    