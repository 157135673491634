
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.option {
  color: Color.$eclipse;
  padding: 8px 0 8px 16px;
  cursor: pointer;

  &:hover,
  &.focused {
    background-color: Color.$snow-hover;
  }

  &:active {
    background-color: Color.$snow-active;
  }

  &.disabled {
    color: Color.$slate;
  }

  &.selected {
    background-color: Color.$surfie;
    color: Color.$snow;

    &:hover,
    &.focused {
      background-color: Color.$surfie-hover;
    }

    &:active {
      background-color: Color.$surfie-active;
    }
  }
}

.iconOption {
  display: flex;
  align-items: center;
  max-width: 100%;
  min-width: 0;

  .label {
    @include Mixin.text-ellipsis();
  }

  svg {
    margin-right: 5px;
    flex-shrink: 0;
  }
}


.selectInternals {
  :global(.react-select__control) {
    min-height: 32px;
    box-shadow: none;

    // react-select does transition: all but that messes with the outline on focus state :/ so overriding it here
    transition: border-color 100ms;

    &,
    &:hover {
      border-color: Color.$gainsboro;
    }

    &:focus,
    &:global(.react-select__control--is-focused) {
      border-color: Color.$gainsboro;
      outline: Color.$surfie-dark solid 2px !important;
      outline-offset: -1px;
    }
  }

  :global(.react-select__value-container) {
    padding: 0 8px;
  }

  :global(.react-select__single-value) {
    color: Color.$eclipse;
  }

  :global(.react-select__menu-list) {
    padding: 16px 0;
  }

  :global(.react-select__indicator) {
    padding: 5px;

    svg {
      fill: Color.$surfie;
    }
  }

  :global(.react-select__clear-indicator) {
    display: flex;
  }

  :global(.react-select__indicator-separator) {
    background-color: Color.$gainsboro;
    margin-bottom: 4px;
    margin-top: 4px;
  }

  :global(.react-select__menu) {
    z-index: Var.$select-zindex !important;
  }

  :global(.react-select__control--is-disabled) {
    background-color: Color.$smoke;

    /* stylelint-disable-next-line selector-max-compound-selectors */
    :global(.react-select__indicator) svg {
      fill: Color.$slate;
    }
  }

  :global(.react-select__placeholder) {
    white-space: nowrap;
  }
}

:global(.react-select__menu-portal) {
  z-index: Var.$select-zindex !important;
}

    