
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.button {
  display: flex;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  justify-content: center;
  align-items: stretch;
  border: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  text-decoration: none;

  @include Media.medium {
    font-size: 14px;
    line-height: inherit;
  }

  &.size-sm {
    padding: 8px 12px;

    @include Media.medium {
      padding: 5px 12px;
    }
  }

  &.size-md {
    padding: 12px 16px;

    @include Media.medium {
      padding: 9px 16px;
    }
  }

  &.size-lg {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    padding: 16px 8px;
  }

  &.variant-standard {
    &.type-primary {
      background-color: Color.$surfie;
      color: Color.$snow;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.33);
      outline-offset: 2px;

      &:hover {
        background-color: Color.$surfie-hover;
      }

      &:active {
        background-color: Color.$surfie-active;
      }
    }

    &.type-secondary {
      background-color: Color.$snow;
      color: Color.$surfie;

      &:hover {
        background-color: Color.$snow-hover;
      }

      &:active {
        background-color: Color.$snow-active;
      }
    }
  }

  &.variant-destructive {
    &.type-primary {
      background-color: Color.$punch;
      color: Color.$snow;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.33);
      outline-offset: 2px;

      &:hover {
        background-color: Color.$punch-hover;
      }

      &:active {
        background-color: Color.$punch-active;
      }
    }

    &.type-secondary {
      background-color: Color.$snow;
      color: Color.$punch;

      &:hover {
        background-color: Color.$snow-hover;
      }

      &:active {
        background-color: Color.$snow-active;
      }
    }
  }

  &.variant-standard,
  &.variant-destructive {
    &.type-primary,
    &.type-secondary {
      &.disabled {
        background-color: Color.$gainsboro;
        color: Color.$slate;
        cursor: default;
        box-shadow: none;
      }
    }
  }
}

    