
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.wrapper {
  position: relative;
  display: block;
  height: 24px;
}

.hitSlop {
  position: absolute;
  inset: -4px;
  cursor: pointer;
}

.input {
  box-sizing: border-box;
  position: absolute;
  opacity: 0;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
}

.indicator {
  position: relative;
  display: inline-flex;
  width: 24px;
  height: 24px;
  color: Color.$snow;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border-radius: 3px;

  .box {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    .stroke { stroke: Color.$eclipse; }
    .fill { fill: Color.$snow; }
  }

  input:indeterminate ~ &,
  input:checked ~ & {
    .stroke { stroke: Color.$surfie; }
    .fill { fill: Color.$surfie; }
  }

  input:disabled ~ & {
    cursor: default;
    .stroke { stroke: Color.$gainsboro; }
  }

  input:focus-visible ~ & {
    outline: Color.$surfie-dark solid 2px;
    outline-offset: -2px;
  }

  &.hasContent {
    margin-right: 8px;
  }
}

.label {
  vertical-align: middle;
}

.icon {
  pointer-events: none;
  user-select: none;
}

    