@use 'app/styles/color' as Color;
@use 'app/styles/var' as Var;

@mixin no-print {
  @media print {
    display: none;
  }
}

@mixin text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

// Hide content visually; show it to screen readers
//
// - CSS recommended by 18F [1]
// - Additional explanation from Orange [2]
//
// [1]: https://accessibility.18f.gov/hidden-content/
// [2]: https://a11y-guidelines.orange.com/en/web/components-examples/accessible-hiding/
@mixin sr-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

@mixin shadow ($opacity: 0.2) {
  box-shadow: 0 2px 6px rgba(0, 0, 0, $opacity);
}

@mixin sticky-mask($backgroundColor, $height) {
  &::before {
    // Mask for content scrolling under
    content: '';
    width: 100%;
    height: $height;
    top: -$height;
    left: 0;
    right: 0;
    position: absolute;
    background-color: $backgroundColor;
    z-index: -1;
  }
}

@mixin sticky-header($borderColor, $backgroundColor) {
  @include sticky-mask($backgroundColor, 16px);

  position: sticky;
  padding-bottom: 16px;
  border-bottom: 1px solid $borderColor;
  margin-bottom: 16px;
  top: 16px;
  background-color: $backgroundColor;
  z-index: Var.$nav-zindex - 1;
}

@mixin button-reset() {
  padding: 0;
  border: 0;
  background: inherit;
  color: Color.$eclipse;
  font-family: Var.$font-family;
  font-size: 14px;
  line-height: calc(22 / 14);
}

@mixin signature() {
  font-family: "PortraitScriptBounceBold", cursive;
  font-size: 32px;
  font-weight: 400;
}
