
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.link {
  text-decoration: none;
  font-weight: bold;
  display: block;
  text-align: left;
  border-radius: 2px;
  outline-offset: 2px;

  &.creative {
    color: Color.$surfie;

    &:hover {
      color: Color.$surfie-hover;
    }

    &:active {
      color: Color.$surfie-hover;
    }
  }

  &.destructive {
    color: Color.$punch;

    &:hover {
      color: Color.$punch-hover;
    }

    &:active {
      color: Color.$punch-hover;
    }
  }

  &.disabled,
  &.disabled:hover,
  &.disabled:active {
    cursor: default;
    color: Color.$slate;
  }

  &.inline {
    display: inline;
  }
}

/* stylelint-disable-next-line selector-no-qualifying-type */
button.link {
  background: none;
  border: 0;
  cursor: pointer;
  padding: 0;
}

    