
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.footer:not(:empty) {
  padding: 16px;

  @include Media.medium {
    padding: 24px;
  }
}

    