
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.company {
  font-size: 12px;

  @include Media.medium {
    font-size: 14px;
    font-weight: bold;

    .sellerName {
      font-size: 12px;
      line-height: 18px;
      font-weight: normal;
    }
  }
}

.switch {
  font-weight: bold;
  color: Color.$surfie;
  font-size: 12px;
  margin-left: 8px;
}

    