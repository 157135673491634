
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.control {
  border: 1px solid Color.$gainsboro;
  border-radius: 8px;
  position: relative;
  overflow: visible;

  &.type-secondary {
    background-color: Color.$smoke;

    .segment {
      &.active {
        color: Color.$surfie;
      }
    }

    .highlight::before {
      background-color: Color.$snow;
    }
  }

  &.size-sm {
    .segments {
      height: 34px;
    }
  }
}

.segments {
  display: flex;
  height: 40px;
  justify-content: center;
  align-items: center;
  position: relative;
}

.segment {
  flex-basis: 100%;
  text-align: center;
  font-weight: bold;
  color: Color.$surfie;
  background: none;
  border: 0;
  height: 100%;
  transition: color 300ms;
  border-radius: 4px;

  &.active {
    color: Color.$snow;
  }
}

.highlight {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 2px;
  transition: left 300ms;

  // Background used as a psuedo element so we can use "width: 50%" and not have to account for margins
  // (it is padding now im main element)
  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: Color.$surfie;
    border-radius: 6px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.33);
  }
}

    