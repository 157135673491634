
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

@use 'sass:color';

.levelBadge {
  border-radius: 0.5em;
  border: 1px solid Color.$gainsboro;
  padding: 0 0.5em;
  text-transform: uppercase;
  font-size: 90%;
  font-weight: 600;
  min-width: 5em;
  display: inline-block;
  line-height: 1.3;
  text-align: center;

  &.trace {
    border-color: Color.$slate;
    color: Color.$slate;
    background-color: color.adjust(Color.$slate, $alpha: -0.95);
  }

  &.debug {
    border-color: Color.$eclipse;
    color: Color.$eclipse;
    background-color: color.adjust(Color.$eclipse, $alpha: -0.95);
  }

  &.info {
    border-color: Color.$sea;
    color: Color.$sea;
    background-color: color.adjust(Color.$sea, $alpha: -0.95);
  }

  &.warn {
    border-color: Color.$jaffa;
    color: Color.$jaffa;
    background-color: color.adjust(Color.$jaffa, $alpha: -0.95);
  }

  &.error {
    border-color: Color.$punch;
    color: Color.$punch;
    background-color: color.adjust(Color.$punch, $alpha: -0.95);
  }

  &.fatal {
    border-color: Color.$punch;
    background-color: Color.$punch;
    color: Color.$snow;
  }
}

.logLine {
  padding: 8px 0;
  border-bottom: 1px solid Color.$gainsboro;
  cursor: pointer;

  code {
    white-space: pre;
  }
}

.viewer {
  overflow-y: auto;
}
    