
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.editableLabel {
  svg {
    margin-left: 0;
  }
}

.tooltip {
  a {
    display: block;
  }
}

.option {
  small {
    display: block;
  }
}

:global(.react-select__single-value) {
  .option small {
    display: none;
  }
}

    