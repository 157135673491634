$small-width: 576px;
$medium-width: 768px;
$large-width: 992px;
$xlarge-width: 1190px;

@mixin lt-small {
  @media (max-width: #{$small-width - 1px}) {
    @content;
  }
}

@mixin lt-medium {
  @media (max-width: #{$medium-width - 1px}) {
    @content;
  }
}

@mixin lt-large {
  @media (max-width: #{$large-width - 1px}) {
    @content;
  }
}

@mixin lt-xlarge {
  @media (max-width: #{$xlarge-width - 1px}) {
    @content;
  }
}

@mixin small {
  @media (min-width: #{$small-width}) {
    @content;
  }
}

// This is the switch to "Desktop" mode in JS
@mixin medium {
  @media (min-width: #{$medium-width}) {
    @content;
  }
}

@mixin large {
  @media (min-width: #{$large-width}) {
    @content;
  }
}

@mixin xlarge {
  @media (min-width: #{$xlarge-width}) {
    @content;
  }
}

@mixin supports-hover {
  @media (hover: hover) and (pointer: fine) {
    @content;
  }
}

@mixin media($types...) {
  @each $type in $types {
    @if ($type == lt-small) {
      @include lt-small {
        @content;
      }
    } @else if ($type == lt-medium) {
      @include lt-medium {
        @content;
      }
    } @else if ($type == lt-large) {
      @include lt-large {
        @content;
      }
    } @else if ($type == lt-xlarge) {
      @include lt-xlarge {
        @content;
      }
    } @else if ($type == small) {
      @include small {
        @content;
      }
    } @else if ($type == medium) {
      @include medium {
        @content;
      }
    } @else if ($type == large) {
      @include large {
        @content;
      }
    } @else if ($type == xlarge) {
      @include lt-large {
        @content;
      }
    } @else if ($type == print) {
      @media print {
        @content;
      }
    }

  }
}