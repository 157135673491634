
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.button {
  &.menuOpen {
    background-color: Color.$smoke !important;
  }
}

.buttonContent {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;

  .title {
    margin-left: auto;
    flex-grow: 1;
  }
}

.popover {
  .menuItem {
    padding: 12px;
    display: flex;
    font-size: 14px;
    align-items: center;
    flex-wrap: wrap;

    svg {
      margin-right: 12px;
    }

    &:hover:enabled {
      background-color: Color.$snow-active;
      color: Color.$surfie-active;
    }

    &.destructive:hover:enabled {
      background-color: Color.$snow-active;
      color: Color.$punch-active;
    }

    &.menuItemBorder {
      border-top: 1px solid Color.$gainsboro;
    }

    &.hasSubtitle {
      padding: 12px 12px 4px;
    }
  }
}

.subtitle {
  flex-basis: 100%;
  padding: 0 12px 12px;
  max-width: 240px;
}

.popover {
  background-color: Color.$cloud;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 4px 0;
}

.popoverContainer {
  z-index: 2;
  margin-right: -8px;
}

    