
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.userMenu {
  @include Mixin.button-reset();

  @include Media.medium {
    padding: 8px;
    margin: 0 0 8px;
    border-radius: 4px;
    width: 100%;

    &:hover {
      background-color: Color.$smoke;
    }

    &:active {
      background-color: Color.$gainsboro;
    }
  }

  &.variant-onboarding {
    padding: 8px;
    margin: 0 0 8px;
    border-radius: 8px;
    width: 100%;

    background-color: Color.$surfie-dark;
    color: Color.$snow;
  }
}

.popoverContainer {
  z-index: Var.$dropdown-zindex;
}

.popover {
  width: 224px;
  background-color: Color.$cloud;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 8px 0;
}

.username {
  @include Mixin.text-ellipsis();
  padding: 0 16px 8px;
  border-bottom: 1px solid Color.$gainsboro;
}

.signIn {
  svg {
    vertical-align: top;
    margin-right: 12px;
  }
}

// Have to increase specificity due to ActionLink
.popover .menuItem > * {
  padding: 16px;
  display: block;
  width: 100%;

  &:hover {
    background-color: Color.$snow-active;
    color: Color.$surfie-active;
  }
}

    