
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.caret {
  transition: transform 300ms;
}

.gray {
  color: Color.$slate;
}

.green {
  color: Color.$surfie;
}

.flipped {
  transform: rotate(180deg);
}

    