
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.step {
  position: absolute;
  width: 100%;
  top: 0;

  &.animating {
    transition: transform 300ms;
  }
}

    