
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.recipient {
  display: flex;
  align-items: center;

  div:first-child {
    width: 75%;
    @include Mixin.text-ellipsis();
  }
}

.moreLabel {
  color: Color.$slate;
}

.badge {
  display: flex;
  gap: 2px;
  align-items: center;
  margin-left: 4px;
  flex-shrink: 0;
}

.unknownLabel {
  font-weight: 700;
  color: Color.$slate;
  margin-left: 2px;
}

.tooltip {
  display: flex;
  align-items: center;
}

    