
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.contactsHeader {
  margin: 16px 0 8px;
}

.contactList {
  margin: 0;

  li {
    list-style-type: '•';
    padding-left: 6px;
  }
}

    