
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.selectRow {
  @include Media.medium {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  margin-top: 16px;
}

.select {
  min-width: 80px;

  @include Media.lt-medium {
    margin-top: 4px;
  }
}

    