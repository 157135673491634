
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

@use 'sass:color';

.confirmation {
  padding-bottom: 8px;

  p {
    margin-bottom: 32px;
  }

  button + button {
    margin-top: 8px;
  }
}

.icon {
  margin: 0 auto 32px;
  background-color: color.adjust(Color.$surfie, $alpha: -0.8);
  border-radius: 50%;
  width: 96px;
  height: 96px;
  color: Color.$surfie;
  display: flex;
  justify-content: center;
  align-items: center;
}

.success {
  padding-bottom: 8px;

  h3 {
    margin-bottom: 8px;
  }

  h3 {
    text-align: left;
  }

  .removedUserMessage {
    margin-bottom: 16px;
  }

  .lastUserSuggestion {
    margin: 8px auto 16px;
  }

  .newUserSection {
    text-align: initial;
  }
}

    