
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.content {
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
}

.header {
  padding: 32px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttonWrapper {
  width: 24px;
  height: 24px;
}

.body {
  border: 1px solid Color.$gainsboro;
}

.brandedLogo {
  object-fit: contain;
  max-width: 160px;
  max-height: 160px;
}

.poweredByLogo {
  padding-top: 10px;
  text-align: center;
}

.customerFormLink {
    text-align: center;
    margin: 45px auto 0;
}

    