
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.headerSpacing {
  margin-bottom: 16px;
}

.topNavBar {
  z-index: 1;
}

.cobrandingLogo {
  margin: 64px auto 32px;
  max-height: 84px;
  width: auto;
  max-width: 300px;
  display: block;

  @include Media.medium {
    max-width: 100%;
  }
}

    