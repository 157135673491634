
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.badge + .badge {
  display: block;
  margin-top: 4px;
  width: max-content;

  @include Media.lt-medium {
    display: inline-block;
    margin-top: 0;
  }
}

.badge {
  @include Media.lt-medium {
    margin-left: 4px;
  }
}

    