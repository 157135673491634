
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.textarea {
  height: 200px;
}

.textAreaHolder {
  position: relative;
}

.loadingMessage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  gap: 8px;
}

.toggleWrapper {
  margin: 16px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.toggleLabel {
  display: inline-flex;
  flex-direction: column;
}

.bulkCheckbox {
  margin-left: -4px;
}

.newUserSection {
  padding-top: 4px;
}

.composition div:last-child {
  margin-bottom: 0;
}

    