
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.tab {
  padding: 16px 0;
  cursor: pointer;

  @include Media.lt-medium {
    border-bottom: 1px solid Color.$gainsboro;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @include Media.medium {
    flex: 1 1 0px;

    &:not(:last-child) {
      margin-right: 16px;
    }
  }

  @include Media.supports-hover {
    &:hover {
      background-color: Color.$cloud;
    }

    &:active {
      background-color: Color.$smoke;
    }
  }

  &.selected {
    border-bottom: 2px solid Color.$surfie;
  }

  .arrow {
    display: flex;
    color: Color.$surfie;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;

    @include Media.medium {
      display: none;
    }
  }
}

.title {
  color: Color.$surfie;
  font-size: 16px;
  font-weight: 600;
}

    