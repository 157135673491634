
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.buttonBar {
  width: 100%;
}

.list {
  text-align: left;
  margin-bottom: 0;
}

.errorToast {
  display: flex;
  justify-content: center;

  svg {
    margin-right: 12px;
  }
}

    