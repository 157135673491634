
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.headerGrid {
  display: grid;
  column-gap: 16px;
  row-gap: 8px;
  grid-template-columns: max-content repeat(1, 1fr);

  @include Media.medium {
    grid-template-columns: max-content repeat(1, 1fr);
  }

  margin-bottom: 24px;
}

.screen {
  h2 {
    margin-bottom: 8px;
  }

  h3 {
    margin: 8px 0;
  }
}

.header {
  font-weight: 700;
}

.recipients {
  @include Media.lt-medium {
    min-width: 50px;

    > div {
      @include Mixin.text-ellipsis();
    }
  }
}

.messageFrame {
  border-radius: 4px;
  border: 1px solid Color.$smoke;
  max-width: 100%;
  overflow: auto;

  @include Media.medium {
    max-width: 740px;
  }

  iframe {
    border: 0;
    margin: 0 auto;
    width: 100%;
  }
}

.pagination {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 8px;
  max-width: 740px;
  align-items: center;
  flex-shrink: 0;

  @include Media.lt-small {
    max-width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }

  .recipient {
    max-width: 100%;
    @include Mixin.text-ellipsis();
  }
}

.attachmentsList {
  margin-top: 8px;
  margin-bottom: 0;

  .bytes {
    text-transform: lowercase;
  }
}

.recipientGrid {
  max-width: 560px;
  display: grid;
  grid-template-columns: minmax(272px, 1fr) max-content 1fr;
  row-gap: 8px;
  column-gap: 16px;
  align-items: center;
  margin-bottom: 24px;

  .recipientContact {
    @include Mixin.text-ellipsis();
  }

  .recipientBadge {
    display: flex;
  }

  .recipientDate {
    @include Mixin.text-ellipsis();
  }

  @include Media.lt-medium {
    display: block;
    max-width: 100%;

    .header {
      display: none;
    }

    .recipientContact {
      display: block;
      font-weight: 700;
      margin-bottom: 8px;
    }

    .recipientBadge {
      display: inline-flex;
      vertical-align: -3px;
      margin-left: -4px;
    }

    .recipientDate {
      display: inline;
      margin-left: 8px;
    }

    .recipientDate + .recipientContact {
      border-top: 1px solid Color.$gainsboro;
      margin-top: 8px;
      padding-top: 8px;
    }

    .contactsLink {
      margin-top: 8px;
    }
  }
}


    