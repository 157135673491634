
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.usernameChip {
  max-width: 100%;
  height: 20px;

  @include Media.lt-medium {
    max-width: 172px;
  }
}

    