
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.modal {
  display: flex;
  flex-direction: column;
  gap: 8px;

  svg {
    margin: 0 auto;
  }

  .description {
    margin-bottom: 8px;
  }
}

    