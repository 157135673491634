
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.input {
  display: flex;
  align-items: center;

  :nth-child(2) {
    margin-left: 6px;
  }
}

.input + .input {
  margin-top: 8px;
}
    