
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.input {
  border: 1px solid Color.$gainsboro;
  border-radius: 4px;
  padding: 8px;
  width: 100%;
  transition: border-color 200ms;
  resize: none;
  color: Color.$eclipse;

  &.invalid {
    border-color: Color.$punch;
  }

  &:disabled {
    background-color: Color.$smoke;
  }

  &.resizable {
    resize: both;
  }
}

    