
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.loadingTooltip {
  width: 256px;
}

.tooltipAction {
  color: Color.$snow !important;
  text-decoration: underline;
}

    