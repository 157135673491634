
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.container:last-child {
  margin-left: 4px;
}

.container:first-child {
  margin-right: 4px;
}

.inactive {
  visibility: hidden;
  color: Color.$gainsboro;
}

@media (hover: hover) {
  :global(.row):hover {
    .inactive {
      visibility: visible;
    }
  }
}

    