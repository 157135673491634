
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.row {
  margin: 4px 0;

  @include Media.medium {
    display: grid;

    &:hover {
      background-color: Color.$cloud;
    }
  }

  h4 {
    display: none;
  }

  @include Media.lt-medium {
    .cell + .cell {
      margin-top: 16px;
    }
  }
}

.userCell {
  display: flex;
  gap: 16px;

  >:first-child {
    flex-shrink: 0;
  }
}

.userInfo {
  display: flex;
  flex-direction: column;

  flex-shrink: 1;
  min-width: 0;
  align-self: center;

  @include Media.medium {
    gap: 4px;
  }

  h3 {
    @include Mixin.text-ellipsis();
  }

  .infoRow {
    display: flex;
    gap: 4px;
    align-items: center;
    min-width: 0;

    svg {
      flex-shrink: 0;
    }

    > div {
      @include Mixin.text-ellipsis();
    }
  }
}

.avatarCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 40px;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
}

@include Media.medium {
  .horizontalRow {
    display: grid;
    grid-column: span 4;
    grid-template-columns: subgrid;
    padding: 8px;
  }
}

@include Media.medium {
  .cell {
    flex-shrink: 0;
    flex-grow: 1;

    &:not(:last-child) {
      margin-right: 16px;
    }
  }
}

@include Media.lt-medium {
  .row {
    width: 100%;
    margin: 8px 0 0;
    padding: 8px 0 16px;
    border-bottom: 1px solid Color.$gainsboro;

    h4 {
      display: block;
      margin: 4px 0;
    }
  }

  .cell, .userCell {
    width: 100%;
  }

  .cell:not(:first-child) {
    padding-left: 56px;
  }

  .buttonCell {
    margin-top: 8px;
  }
}

.buttonCell {
  @include Media.medium {
    display: flex;
    align-items: center;
    width: auto;
    padding-right: 0;
    justify-content: flex-end;
  }
}

.headerText {
  font-weight: bold;
  margin-bottom: 8px;
}

.notifications {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.notificationRow {
  display: flex;
  gap: 4px;
  align-items: center;

  svg {
    flex-shrink: 0;
  }

  &.on {
    svg {
      color: Color.$surfie;
    }
  }

  &.off {
    svg {
      color: Color.$punch;
    }
  }

  &.disabled {
    svg {
      color: Color.$slate;
    }
  }
}

.paymentAccountRow {
  display: inline-flex;
  gap: 4px;
  align-items: center;

  svg {
    flex-shrink: 0;
  }

  &.pay, &.manage {
    svg {
      color: Color.$surfie;
    }
  }

  &.read {
    svg {
      color: Color.$punch;
    }
  }
}

.notificationTitle {
  margin-bottom: 8px;
}

    