
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.backgroundImage {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  border-style: solid;
}

    