
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.editableTitle {
  display: flex;
  max-width: 560px;
  align-items: center;

  @include Media.lt-medium {
    flex-wrap: wrap;
  }
}

.badge {
  margin-left: 8px;
}

.editableTitleAction {
  justify-self: flex-end;
  margin-left: auto;
  font-size: 14px;

  @include Media.lt-medium {
    flex-basis: 100%;
    margin-top: 4px;
  }
}

    