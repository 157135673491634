
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.screen {
  padding: 16px;

  @include Media.medium {
    max-width: 960px;
    margin: 0 auto;
    padding: 32px;
  }
}

.logo {
  margin: 16px auto 0;
  display: flex;
  justify-content: center;
}

.banner {
  border-radius: 4px;
  border: 1px solid Color.$smoke;
  background: Color.$cloud;
  padding: 16px;
  margin-top: 24px;

  h2 {
    margin-bottom: 8px;
  }

  div + div {
    margin-top: 16px;
  }
}

.message {
  white-space: pre-wrap;
}

    