
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.innerSection {
  padding-top: 8px;
}

.buttonBar {
  display: flex;
  margin-top: 16px;
  max-width: 560px;

  > *:first-child {
    margin-right: 4px;
  }

  > *:last-child {
    margin-left: 4px;
  }
}

.editButton {
  width: auto !important;
  margin-top: 16px;
  display: inline-block;
}

.error {
  display: block;
  color: Color.$punch;
  margin-top: 16px;
}

    