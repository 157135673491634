
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.container {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.reportDate {
    font-style: italic;
}

.value {
    font-weight: 700;
}

.positiveValue {
    color: Color.$surfie;
}

.negativeValue {
    color: Color.$punch;
}

.hiddenField {
    font-weight: 700;
}

    