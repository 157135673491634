
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.tabs {
  margin-bottom: 16px;

  @include Media.medium {
    display: flex;
    border-bottom: 1px solid Color.$gainsboro;
    margin-left: 0 - Var.$hover-inset;
    margin-right: 0 - Var.$hover-inset;
  }
}

.tabs.desktop {
  display: none;

  @include Media.medium {
    display: flex;
  }
}

.tabs.mobile {
  display: block;

  @include Media.medium {
    display: none;
  }
}

.popoverContainer {
  z-index: Var.$dropdown-zindex;
}

    