
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.header {
  border-bottom: 1px solid Color.$gainsboro;
  margin-bottom: 24px;
}

.closeButton {
  display: flex;
  background-color: transparent;
  border: 0;
  color: Color.$eclipse;
  padding: 0;
}

.title {
  margin: 20px 0 16px;
}

.steps {
  display: flex;
}

.step {
  display: flex;
  align-items: center;
  padding-bottom: 10px;

  h3 {
    color: Color.$slate;
  }

  &.active {
    h3 {
      color: Color.$surfie;
    }

    .count {
      background: Color.$surfie;
    }
  }

  &.selected {
    position: relative;
    cursor: default;

    &::after {
      position: absolute;
      bottom: -1px;
      width: 100%;
      height: 2px;
      background-color: Color.$surfie;
      content: '';
    }
  }
}

.count {
  display: flex;
  width: 20px;
  height: 20px;
  border-radius: 24px;
  background-color: Color.$gainsboro;
  color: Color.$snow;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  margin-right: 8px;
}

.checkmark {
  display: flex;
  align-items: center;
  justify-content: center;
  color: Color.$surfie;
  background: Color.$snow;
  margin-right: 8px;
}

.separator {
  color: Color.$eclipse;
  padding: 0 20px 10px;
  display: flex;
  align-items: center;
}

    