
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.filterBar {
  @include Media.medium {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-end;
    @include Mixin.sticky-header(Color.$gainsboro, Color.$snow);
  }

  > div:first-child,
  > div:nth-child(2) {
    flex-basis: 50%;
    max-width: 50%;
    flex-shrink: 1;
    flex-grow: 0;
    margin-right: 16px;
    margin-bottom: 0;

    @include Media.lt-medium {
      max-width: 100%;
      margin-right: 0;
      margin-bottom: 16px;
    }
  }

  > div:first-child:nth-last-child(2) {
    flex-basis: calc(100% - 56px);
    flex-shrink: 1;

    @include Media.medium {
      max-width: calc(100% - 56px);
    }

    ~ div {
      flex-shrink: 1;
      flex-basis: 0;
      margin-right: 0;
    }
  }
}

.clearButton {
  width: fit-content;
  align-self: flex-end;
  flex-shrink: 0;
}

.noOptionsText {
  text-align: left;
  color: Color.$slate;
}


.paymentMethodSelect {
  :global(.react-select__value-container) {
    flex-wrap: nowrap;
    mask-image: linear-gradient(90deg, rgba(0, 0, 0, 1) 95%, transparent 100%);
    height: 30px;
  }
}

.actionLink {
  align-self: flex-end;
  flex-shrink: 0;
  margin-top: 16px;

  @include Media.lt-small {
    &.hideClear {
      display: none;
    }
  }

  @include Media.medium {
    .filterCount {
      display: none;
    }
  }

  @include Media.lt-medium {
    @include Mixin.sticky-mask(Color.$snow, 16px);

    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    background-color: Color.$snow;
    z-index: 1;
    top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid Color.$gainsboro;
  }
}

    