
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.confirmAuthorizationStep {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  button + button {
    margin-top: -8px;
  }
}

.authorizationBox {
  border-radius: 4px;
  border: 1px solid Color.$sea-lighter;
  background-color: Color.$sea-lightest;
  padding: 16px;
  display: flex;
  gap: 16px;

  .companyLogo {
    flex-shrink: 0;
  }
}
    