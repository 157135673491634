
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.badge {
  border-radius: 2px;
  font-weight: bold;
  transition: background-color 250ms, max-width 250ms, border-radius 100ms;

  display: inline-block;
  color: Color.$snow;
  overflow: hidden;
  position: relative;
  height: 16px;

  .validIconHolder {
    opacity: 0;
    transition: opacity 10ms;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .title {
    opacity: 1;
    padding: 0 4px;
    font-size: 12px;
    line-height: calc(16 / 12);
    transition: opacity 100ms 200ms;
  }

  &.success {
    border-radius: 16px;
    background-color: Color.$surfie;
    color: Color.$surfie;

    .validIconHolder {
      opacity: 1;
      transition: opacity 100ms 200ms;
    }

    .title {
      opacity: 0;
      transition: opacity 10ms;
    }
  }

  &:not(.success).default {
    background-color: Color.$slate;
    color: Color.$snow;
  }

  &:not(.success).warning {
    color: Color.$snow;
    background-color: Color.$punch;
  }

  &:not(.success).attention {
    color: Color.$snow;
    background-color: Color.$jaffa;
  }

  &:not(.success).promotion {
    background-color: Color.$surfie;
    color: Color.$snow;
  }
}

.validIcon {
  color: Color.$surfie;
  transform: translate(-3px, -3px);
  display: block;
}

    