
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.inlineData {
  display: flex;
  gap: 4px;
}

.applicationDetails {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  h2 {
    margin-bottom: 8px;
  }

  .subheader {
    margin: 16px 0 8px;
  }

  .alert {
    max-width: 560px;
    margin-bottom: 16px;
  }
}

.buttons {
  display: flex;
  gap: 16px;
  max-width: 784px;
  flex-direction: column;

  @include Media.medium {
    flex-direction: row;
  }

  button {
    flex-basis: 50%;
  }

  @include Mixin.no-print();
}

.headerGrid {
  max-width: 560px;
  display: flex;
  flex-direction: column;

  @include Media.media(medium, print) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.dataGrid {
  max-width: 560px;
  gap: 8px;
  display: flex;
  flex-direction: column;

  @include Media.media(medium, print) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  > div {
    display: flex;
    flex-direction: column;
  }

  .span2 {
    grid-column: span 2;
  }
}

.customMessage {
  font-style: italic;
  white-space: pre-wrap;
}

.message {
  white-space: pre-wrap;
}

.additionalUsers {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.additionalUser {
  max-width: 832px;
  grid-template-columns: repeat(3, 1fr);
}

.tradeReferences {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 560px;
}

.tradeReference {
  grid-template-columns: repeat(2, 1fr);
}

.tradeReference, .additionalUser {
  display: flex;
  flex-direction: column;

  gap: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid Color.$gainsboro;

  @include Media.media(medium, print) {
    display: grid;
  }

  > div {
    display: flex;
    flex-direction: column;
  }

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }
}

.paymentCheckpoints {
  svg {
    color: Color.$surfie;
  }

  > div {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}

.paymentPlanList {
  margin: 0;
  padding-left: 14px;

  li {
    list-style-type: '•';
    padding-left: 6px;
  }
}

.signature {
  @include Mixin.signature();
  margin-bottom: 8px;
}

    