
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.avatarHolder {
  display: flex;
  flex-wrap: nowrap;
}

.avatar {
  text-transform: uppercase;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: Color.$slate;
  display: inline-flex;
  color: Color.$snow;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-shrink: 0;
  margin: 3px;
  font-size: 12px;
}

.title {
  @include Mixin.text-ellipsis();
  margin-left: 12px;
}

.icon {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background-color: Color.$cloud;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -6px;
  right: -6px;

  @include Media.lt-medium {
    background-color: Color.$snow;
  }

  svg {
    color: Color.$jaffa;
  }
}

    