
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.body {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.footer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.accessList {
  margin: 0;
  font-size: 12px;
}

.labelDescription {
  margin-bottom: 8px;
}

.modalAlert {
  margin-top: 16px;
}

.alertList {
  margin-top: 0;
  margin-bottom: 0;
}

.warningText {
  color: Color.$punch;
  font-weight: bold;
}

    