
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.header {
  border-bottom: 1px solid Color.$gainsboro;
  padding-bottom: 20px;
}

.section {
  .description {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    svg {
      color: Color.$surfie;
      margin-right: 8px;
      flex-shrink: 0;
    }

    &.disabled {
      svg {
        color: Color.$punch;
      }
    }
  }
}

.toast {
  display: flex;
  align-items: center;

  svg {
    fill: Color.$surfie;
    margin-right: 10px;
  }
}

    