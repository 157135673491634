
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.steps {
  position: relative;
  overflow: hidden;
  width: 100%;
  transition: height 300ms cubic-bezier(0.25, 1, 0.5, 1);
}

    