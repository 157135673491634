
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 416px;
  margin: 32px auto;
  text-align: center;

  @include Media.lt-medium {
    padding: 0 8px;
  }

  h3 {
    margin-top: 16px;
  }

  h2 {
    margin-bottom: 8px;
  }
}

.logo {
  width: 240px;
  height: 80px;
}

.button {
  margin-top: 24px;
  width: 100%;
}

    