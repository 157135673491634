
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.container {
  max-width: 800px;
}

.button {
  font-weight: bold;
  color: Color.$surfie;
  border: 0;
  padding: 0;
  background: none;
  display: inline-flex;
  align-items: center;

  .icon {
    margin-left: 8px;
  }
}

.section + .section {
  margin-top: 16px;
}

.errorBox {
  border: 1px solid Color.$smoke;
  background-color: Color.$cloud;
  padding: 16px;
  margin-top: 16px;
}

.timestamp {
  color: Color.$slate;
}

    