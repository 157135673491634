
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.container {
  padding: 4px 16px 0;
  width: 100vw;
}

.content {
  background-color: Color.$snow;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

    