
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.tagSection {
  margin-bottom: 4px;
  display: flex;
}

.tagCloud {
  margin-left: 12px;

  > * {
    margin-right: 12px;
    margin-bottom: 8px;
  }
}

.tagSection > *:last-child {
  margin-right: 0;
}

.editTagLink {
  display: inline-flex;
  vertical-align: 2px;
  height: 20px;

  > a {
    line-height: 20px;
  }
}

.tagHeader {
  display: inline-flex;
  vertical-align: 2px;
  line-height: 20px;
}

    