
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.pill {
  background-color: Color.$smoke;
  border-radius: 10px;
  display: inline-flex;
  flex-shrink: 0;
  vertical-align: top;

  &:not(:last-child) {
    margin-right: 6px;
  }
}

.label {
  @include Mixin.text-ellipsis();
  display: inline-flex;
  align-items: center;
  padding: 2px 0 2px 4px;
  border-radius: 10px;
  font-weight: 600;
  white-space: nowrap;

  svg {
    margin-right: 5px;
    flex-shrink: 0;
  }

  small {
    @include Mixin.text-ellipsis();
  }
}

.removeButton {
  border-radius: 0 10px 10px 0;
  padding: 2px 4px 2px 2px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover,
  &:global(.isFocused) {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), Color.$smoke;
  }
}

    