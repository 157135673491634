
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.section {
  margin: 16px 0;

  & + & {
    padding-top: 16px;
  }

  & + &.divider {
    border-top: 1px solid Color.$gainsboro;
  }
}

.content {
  margin: 4px 0;
}

    