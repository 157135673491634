
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.container {
  max-width: 560px;

  h1 {
    text-align: center;
    margin-bottom: 16px;
  }

  p {
    margin-bottom: 16px;
  }

  h3 {
    margin-bottom: 4px;
  }

  ul {
    margin-top: 0;
    margin-bottom: 16px;
  }

  button + button {
    margin-top: 8px;
  }
}

.alertBar {
  margin-bottom: 24px;

  p {
    margin-bottom: 0;
  }
}

.alertButton {
  margin-top: 8px;
}

.iconContainer {
  width: 160px;
  height: 160px;
  margin: 0 auto 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.autopaySetupIcon {
    background-color: Color.$surfie-lightest;
    border-radius: 50%;

    svg {
      overflow: visible;
    }
  }
}

.firstButton {
  margin-top: 24px;
  margin-bottom: 8px;
}

.stackedButtons {
  .firstButton {
    margin-top: 0;
  }

  button:nth-child(2) {
    margin-top: 8px;
  }
}

.proposalSubsectionHeader {
  margin-top: 24px;
  margin-bottom: 4px;

  @include Media.medium {
    margin-top: 32px;
  }
}

.reasonsList {
  padding: 0;
  list-style-position: inside;
}

    