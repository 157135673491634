
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.indicator {
  margin-right: 4px;
}

.text {
  display: flex;
  align-items: center;

  &.pastDue {
    color: Color.$punch;
  }
}

    