
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.bar {
  display: flex;
  align-items: center;

  @include Media.medium {
    padding-left: 0;
    padding-right: 0;
  }

  .button:not(:first-child) {
    margin-left: 8px;
  }
}

.button {
  display: flex;
  width: 100%;
  max-width: 560px;
  flex-direction: column;
}

@include Media.lt-medium {
  .twoUp > * {
    flex-basis: 50%;
    flex-shrink: 1;
    max-width: 50%;
  }
}

.action {
  margin-left: 16px;
  flex-shrink: 0;
}

.moreButton {
  margin-left: 16px;
}

.popover {
  .menuItem {
    padding: 16px;
    display: block;
    font-size: 14px;
    width: 100%;

    &:hover:enabled {
      background-color: Color.$snow-active;
      color: Color.$surfie-active;
    }
  }

  .menuSection:not(:last-child) {
    border-bottom: 1px solid Color.$gainsboro;
  }
}

.popover {
  width: 224px;
  background-color: Color.$cloud;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 8px 0;
}

.popoverContainer {
  z-index: 2;
  margin-right: -8px;
}

    