
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.header {
  @include Mixin.sticky-header(Color.$gainsboro, Color.$snow);
}

.filterBar {
  margin-top: 16px;
  display: flex;
  gap: 0;
  flex-direction: column;

  @include Media.medium {
    flex-direction: row;
    gap: 16px;
    flex-wrap: nowrap;
    align-items: flex-end;
  }

  .filter {
    flex-basis: 33%;
    max-width: 33%;
    flex-shrink: 1;
    flex-grow: 0;

    @include Media.lt-medium {
      max-width: 100%;
    }
  }
}

.filter {
  @include Media.medium {
    margin-bottom: -12px;
  }
}

.inline {
  gap: 4px;
  display: flex;
  align-items: center;
  white-space: wrap;
  @include Mixin.text-ellipsis();
  flex-wrap: wrap;
}

.mobileSubject {
  max-width: 200px;
  @include Mixin.text-ellipsis();
}

.row {
  border-bottom: 1px solid Color.$gainsboro;

  > div {
    align-items: flex-start !important;
  }
}

    