
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.badge {
  border-radius: 2px;
  padding: 0 4px;
  font-weight: bold;
  display: inline-block;

  &.right {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.left {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.badgeHolder {
  display: inline-flex;
}

.iconBadge {
  display: inline-flex;
  align-items: center;
  gap: 4px;
}

.default {
  background-color: Color.$slate;
  color: Color.$snow;
}

.warning {
  color: Color.$snow;
  background-color: Color.$punch;
}

.attention {
  color: Color.$snow;
  background-color: Color.$jaffa;
}

.promotion {
  background-color: Color.$surfie;
  color: Color.$snow;
}

.arrow.left {
  background-color: transparent;
  display: inline-flex;
  width: 0;
  height: 0;

  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;

  &.attention {
    border-right: 8px solid Color.$jaffa;
  }

  &.warning {
    border-right: 8px solid Color.$punch;
  }

  &.promotion {
    border-right: 8px solid Color.$surfie;
  }

  &.default {
    border-right: 8px solid Color.$slate;
  }
}

.arrow.right {
  background-color: transparent;
  display: inline-flex;
  width: 0;
  height: 0;

  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;

  &.attention {
    border-left: 8px solid Color.$jaffa;
  }

  &.warning {
    border-left: 8px solid Color.$punch;
  }

  &.promotion {
    border-left: 8px solid Color.$surfie;
  }

  &.default {
    border-left: 8px solid Color.$slate;
  }
}

    