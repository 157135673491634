
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.wrapper {
  margin-bottom: 12px;
}

.labelComponent {
  display: block;
}

.label {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
}

.labelContent {
  display: flex;
  align-items: center;
}

.labelText {
  line-height: 22px;
  font-weight: bold;
  display: inline-block;
  margin-right: 12px;
}

.warningText {
  color: Color.$punch;
  font-weight: bold;
}

.helperText {
  margin-top: 4px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}

.badge {
  margin-left: 8px;
  vertical-align: text-top;
}

.validIcon {
  margin-left: 6px;
  color: Color.$surfie;
  vertical-align: middle;
}


    