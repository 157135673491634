
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.illustration {
  display: block;
  margin: 0 auto;
}

.quote {
  display: flex;
  padding: 24px 0;
  align-items: center;

  & + & {
    border-top: 1px solid Color.$gainsboro;
  }

  .content {
    flex-shrink: 999;
    margin-left: 24px;
  }
}

.quoteImg {
  width: 64px;
  height: 64px;
  object-fit: contain;
}

.attribution {
  font-weight: bold;
}
    