
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.settings {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.dateLine {
  display: flex;
  align-items: center;
  gap: 4px;

  > * {
    display: inline-flex;
  }

  @include Media.lt-medium {
    flex-wrap: wrap;

    &.modifiedBy {
      /* stylelint-disable-next-line selector-max-compound-selectors */
      .userTag > span > span {
        max-width: calc(100vw - 32px);
      }
    }
  }
}

.metadata {
  padding-bottom: 12px;
}

.input {
  max-width: 560px;
}

.buttons {
  display: flex;
  gap: 8px;

  .buttonIcon {
    margin-right: 4px;
    vertical-align: -2px;
  }
}

.modalAlert {
  margin-top: 16px;
}

.alertList {
  margin-top: 0;
  margin-bottom: 0;
}

    