
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.newUser {
  max-width: 400px;
  margin-top: 16px;

  &:last-child {
    margin-bottom: 48px;
  }
}

.table {
  @include Media.medium {
    display: grid;

    &.columns2 {
      grid-template-columns: repeat(2, 1fr);
    }

    &.columns3 {
      grid-template-columns: minmax(300px, 1fr)  1fr fit-content(50px);
    }

    &.columns4 {
      grid-template-columns: minmax(300px, 1fr) minmax(100px, 250px) 1fr fit-content(50px);
    }
  }
}

    