
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.indicator {
  @include Mixin.no-print();
  position: fixed;
  left: 0;
  top: 0;
  z-index: Var.$server-indicator-zindex;
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-right: 20px solid transparent;
}

    