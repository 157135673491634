
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'


.textCell {
  font-weight: 700;
  min-height: 40px;
  display: inline-flex;
  align-items: center;
}

.textAlign-right {
  justify-self: flex-end;
  text-align: right;
}
  
.textAlign-center {
  justify-self: center;
  text-align: center;
}
  
.pastDue {
  color: Color.$punch;
}

.placeholder {
  color: Color.$slate;
}

@keyframes loading {
  0% {
    background-position: 100% 0;
  }

  100% {
    background-position: -100% 0;
  }
}

.loading {
  position: relative;
  width: 10ch;
  height: 1em;
  border-radius: 4px;
  background: linear-gradient(to right, Color.$smoke 2%, Color.$gainsboro 18%, Color.$smoke 38%);
  background-size: 200%;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: loading;
  animation-timing-function: linear;
}
    