
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.formattedCell.truncate {
  @include Mixin.text-ellipsis();
}

.alignRight {
  justify-content: flex-end;
}

.dollarCredit {
  color: Color.$surfie;
}

.dollarOverdue {
  color: Color.$punch;
}

.dollarOverpaid {
  color: Color.$jaffa;
  align-self: center;
}

.textOverpaid {
  color: Color.$jaffa;
}

.placeholder {
  color: Color.$slate;
}

.header {
  font-weight: bold;
  align-items: flex-end;

  &.alignRight {
    text-align: right;
  }
}

.icon {
  margin-right: 2px;
  display: inline;
  vertical-align: -1px;
}


    