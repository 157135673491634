
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.wrapper {
  @include Mixin.no-print();

  display: none;
  border-radius: 16px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  background-color: Color.$cloud;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 8px;
  position: relative;
  z-index: 1;

  @include Media.medium {
    border-radius: 16px;
    padding-left: 16px;
    padding-right: 16px;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 16px;
  }
}

.content {
  padding: 16px;
  border-top: 1px solid Color.$smoke;
  display: flex;
  flex-direction: column;

  @include Media.medium {
    padding-left: 0;
    padding-right: 0;
    flex-direction: row;
    align-items: center;
  }
}

.enter,
.appear {
  display: block;
  transform: translateY(100%);
}

.enterActive,
.appearActive {
  transform: translateY(0);
  transition: transform 300ms cubic-bezier(0.34, 1.56, 0.64, 1);
}

.enterDone,
.appearDone {
  @include Mixin.no-print();
  display: block;
}

.exit {
  display: block;
  transform: translateY(0);
}

.exitActive {
  display: block;
  transform: translateY(150%);
  transition: transform 300ms cubic-bezier(0.34, 1.56, 0.64, 1);
}

.exitDone {
  transform: translateY(200%);
  display: none;
}

    