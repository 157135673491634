
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.section > * {
  max-width: 560px;
}

.select {
  :global(.react-select__value-container) {
    flex-wrap: nowrap;
    mask-image: linear-gradient(90deg, rgba(0, 0, 0, 1) 95%, transparent 100%);
    height: 32px;
  }
}

.toggles {
  padding-bottom: 16px;
}

    