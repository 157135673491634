
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'


.content {
  white-space: pre-wrap;
  overflow-wrap: break-word;
  overflow: auto;
}

.editWrapper {
  position: relative;
}

.shadowContent {
  visibility: hidden;
  padding: 8px + 1px; // Textarea's padding + border
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.textarea {
  position: absolute;
  inset: 0;
  line-height: inherit;
  overflow-wrap: break-word;
}
    