
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.body {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100%;
  padding: 0 Var.$modal-body-padding;

  @include Media.medium {
    padding: 0 Var.$modal-body-desktop-padding;

    .wrapper:first-child & {
      padding-top: 32px;
    }

    .wrapper:not(.footerHasContent) & {
      padding-bottom: 32px;
    }
  }
}

.wrapper {
  position: relative;
  overflow: hidden;
  height: 100%;

  // Safari: since we are a child of flexbox, height 100% breaks in .body.
  // We must be flex too so 100% represents our height and not the parent flexbox.
  display: flex;
  flex-direction: column;


  &.scrollable:not(:first-child)::before {
    content: '';
    position: absolute;
    top: -1px;
    left: 0;
    right: 0;
    height: 1px;
    transition: box-shadow 200ms;
    box-shadow: 0 0 5px rgb(0, 0, 0);
  }

  &.scrollable:not(:last-child)::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 1px;
    transition: box-shadow 200ms;
    box-shadow: 0 -1px 1px 0 Color.$gainsboro;
  }

  &.scrollable.scrolledTop::before,
  &.scrollable.scrolledBottom::after,
  &.scrollable:not(.footerHasContent)::after {
    box-shadow: none;
  }
}

    