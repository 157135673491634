
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.content {
  padding: 0 0 7px;
  display: flex;
  flex-direction: column;

  h2 {
    margin: 32px 0 8px;
    text-align: center;
  }

  p {
    margin: 0 0 16px;
  }
}

.backButton {
  margin-bottom: 16px;

  svg {
    cursor: pointer;
  }
}

.termsHeader {
  margin-bottom: 6px;
}

.graphic {
  align-self: center;
}

.buttons {
  margin-top: 32px;
  overflow: visible;

  >:first-child {
    margin-bottom: 8px;
  }
}

.form {
  margin-bottom: 20px;
}

.italicize {
  font-style: italic;
}

    