
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.popoverContainer {
  z-index: Var.$dropdown-zindex;
}

.popover {
  width: 224px;
  background-color: Color.$cloud;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 8px 0;
}

.menu {
  background: Color.$snow;
  border-radius: 4px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  padding: 8px 0;
  padding-inline-start: 0;
  width: 240px;
}

.menuItem {
  &:hover {
    background-color: Color.$snow-active;
    color: Color.$surfie-active;
  }
}

    