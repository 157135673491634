
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.container {
  max-width: 560px;
}

.signInPaymentAccountButton {
  margin-bottom: 8px;
}

.alert {
  margin-bottom: 20px;
}

.paymentAccount {
  &:hover {
    background-color: transparent;
  }
}

.paymentAccountButtonHelper {
  text-align: center;
  padding-top: 8px;
}

.labelAsText {
  font-weight: 400;
  font-size: 14px;
}

.form{
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.row {
  display: flex;
  align-items: center;
  gap: 16px;

  > * {
    flex-grow: 99;
  }

  .rightLabel {
    flex-grow: 1;
    font-size: 12px;
  }
}

.additionalControlsSection {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.additionalControlSection {
  padding-left: 48px;

}

.maxAmountSubSection {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.toggle > label {
  display: flex;
  align-items: center;
}

.formButton {
  margin-top: 32px;
}

.feeLabel {
  white-space: nowrap;
}

    