
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.item {
  padding: 16px 0;
  border-bottom: 1px solid Color.$gainsboro;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  gap: 8px;

  &:hover {
    background-color: Color.$cloud;
  }
}

.sellerImage {
  background-color: Color.$cloud;
  border: 1px solid Color.$gainsboro;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-position: center center;
  background-size: cover;
}

.content {
  // This will grow via flex
  width: 1px;
  flex-grow: 999;
}

.title {
  display: flex;
  align-items: center;
}

.itemText {
  @include Mixin.text-ellipsis();

  font-weight: bold;
  color: Color.$surfie;
  flex-shrink: 999;
}

.icon {
  margin-left: 4px;
  color: Color.$surfie;
  display: flex;
}

.itemDescription {
  color: Color.$surfie;

  -webkit-line-clamp: 2;
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.itemDescriptionEmpty {
  color: Color.$slate;
}

.modalBody {
  @include Media.medium {
    min-height: 332px;
  }
}

.stats {
  text-align: right;
  color: Color.$slate;
  flex-shrink: 0;

  &.openBalance {
    color: Color.$eclipse;
  }

  &.pastDue {
    margin-left: 8px;
    color: Color.$punch;
    width: 96px;
    flex-shrink: 1;
  }

  > * {
    @include Mixin.text-ellipsis();
    max-width: 100%;
    display: block;
  }
}

    