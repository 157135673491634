
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.dialogHolder {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: Var.$modal-zindex;
}

.backdrop {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.enter {
  transition: all cubic-bezier(0.25, 1, 0.5, 1) 0.3s;
}

.enterFrom {
  transform: translateX(100%);
}

.enterTo {
  transform: translateX(0);
}

.leave {
  transition: all ease-in 0.3s;
}

.leaveFrom {
  transform: translateX(0);
}

.leaveTo {
  transform: translateX(100%);
}

.panel {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: auto;
  min-width: 208px;
  background-color: Color.$snow;
  z-index: Var.$modal-zindex;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.25);
  padding: 4px;

  @include Media.lt-medium {
    left: 0;
  }
}

.close {
  margin: 10px 0 0 10px;
  height: 24px;
  width: 24px;
  display: inline-block;
}

.menuHeading {
  color: Color.$eclipse;
  padding: 10px 12px 2px;
}

.menuSeparator {
  height: 1px;
  background-color: Color.$gainsboro;
  margin: 4px 8px;
}

.menuItems {
  --anchor-gap: 4px;
  z-index: Var.$dropdown-zindex;
  background-color: Color.$snow;
  border-radius: 4px;
  width: 200px;
  padding: 4px 0;

  // full height minus the padding and close button row
  height: calc(100% - 38px);
  overflow: auto;

  @include Media.lt-medium {
    width: 100%;
  }
}

.menuSection {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.menuItem {
  background-color: inherit;
  border: 0;
  display: flex;
  gap: 8px;
  padding: 10px 12px;
  color: Color.$surfie;
  font-weight: bold;
  width: 100%;
  align-items: center;

  span {
    display: inline-flex;
  }

  &:hover {
    background-color: Color.$smoke;
    color: Color.$surfie-dark;
  }
}

    