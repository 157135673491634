
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.input {
  margin: 1px;
}

.input input {
  text-align: right;

  @include Media.lt-medium {
    max-width: 120px;
  }
}

.error {
  color: Color.$punch;
}

.warning {
  color: Color.$jaffa;
}

.dollarInput {
  display: flex;
  gap: 4px;
  align-items: center;
}

    