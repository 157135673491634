
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.product {
  display: flex;
  flex-direction: row;
  padding: 8px 0;
  align-items: flex-start;

  & + & {
    border-top: 1px solid Color.$gainsboro;
  }

  @include Media.lt-medium {
    justify-content: space-between;
  }
}

.total {
  border-bottom-width: 0;
}

.titles {
  display: flex;
  flex-direction: row;
  font-weight: bold;
}

.column {
  justify-content: center;
  margin-right: 16px;
  width: 128px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  text-align: right;
}

.key-description {
  margin-right: 16px;
  width: 416px;
  flex-grow: 3;
}

.detailsText {
  color: Color.$slate;
  font-size: 12px;
  line-height: 18px;
  white-space: pre-wrap;
}

.caretWrapper {
  height: 18px;
  justify-content: flex-end;
  display: flex;
  align-items: center;
}

.leftColumn {
  flex-shrink: 1;
}

.rightColumn {
  text-align: right;
}

    