
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.wrapper {
  display: flex;
  align-items: center;
  z-index: Var.$nav-zindex;
  position: relative;
}

.companyLogoWrapper {
  print-color-adjust: exact;
  align-self: flex-start;
  margin-right: 16px;
}

.textWrapper {
  flex-shrink: 999;

  &.topLevel h1 {
    @include Media.lt-medium {
      font-size: 32px;
      line-height: 48px;
    }
  }
}

.description {
  margin: 0 0 4px;
}

    