
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.header {
  margin-top: 8px;
  margin-bottom: 16px;
}

.accountSetupUrlForm {
  display: flex;
  flex-direction: column;
  gap: 8px;

  > div {
    display: flex;
    gap: 8px;
  }

  @include Media.medium {
    > div {
      flex-direction: row;;
    }

    > div:first-child {
      flex-grow: 1;
    }

    flex-direction: row;
    align-items: center;
    justify-content: space-between;


    > * {
      margin-right: 16px;
      margin-top: 0 !important;
    }
  }

  > button, > a {
    display: inline-block;
    vertical-align: middle;
    margin-top: 16px;
  }

  > button {
    margin-right: 16px;
  }
}

.accountSetupUrlInput {
  max-width: 560px;
  flex-grow: 1;
}

.linkOutIcon {
  margin-left: 4px;
}

.tooltipButton {
  display: inline-block;
}

.defaultBadge {
  margin-left: 8px;
}

.createButton {
  margin-top: 16px;
}

.formEntry {
    margin-top: 24px;
}

    