
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.tab {
  display: flex;
  padding: 16px;
  align-items: center;

  @include Media.lt-medium {
    &.selected {
      background-color: Color.$surfie;
    }

    &.selected .text {
      color: Color.$snow !important;
    }
  }

  @include Media.medium {
    width: 200px;
    padding: 12px 0;
    margin-right: 8px;
    border-bottom: 2px solid transparent;
  }

  &:hover {
    background-color: Color.$cloud;
  }
}

.icon {
  margin-right: 8px;
}

.text {
  display: flex;

  @include Media.lt-medium {
    align-items: center;
    justify-content: space-between;
    flex-grow: 999;
  }

  @include Media.medium {
    flex-direction: column;
  }
}

    