
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.nameInput {
  max-width: 560px;
}

.accountSetupUrlForm {
  @include Media.medium {
    display: flex;
    align-items: center;

    > * {
      margin-right: 16px;
      margin-top: 0 !important;
    }
  }

  > button, > a {
    display: inline-block;
    vertical-align: middle;
    margin-top: 16px;
  }

  > button {
    margin-right: 16px;
  }
}

.accountSetupUrlInput {
  max-width: 560px;
  flex-grow: 1;
}

    