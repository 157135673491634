
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.section {
  max-width: 992px;
  margin: 32px auto 0;
  padding: 0 16px;

  h1 {
    margin-bottom: 8px;
  }

  h2 {
    margin-bottom: 8px;
    display: flex;
    align-items: center;

    + .formRow {
      margin-bottom: 16px;
    }
  }

  h3 {
    margin: 32px 0 8px;
    display: flex;
    align-items: center;
  }

  h2, h3 {
    .validationBadge {
      margin-left: 8px;
    }
  }

  form {
    margin-top: 16px;
  }

  .icon {
    color: Color.$surfie;
    margin-left: 8px;
  }
}

.subSection {
  display: flex;
  flex-direction: column;
  gap: 16px;

  h3 {
    margin: 0 0 8px;
  }
}

.customMessage {
  white-space: pre-wrap;
}

.errorWithButton {
  display: flex;
  align-items: center;
  gap: 16px;

}

.footer {
  display: flex;
  justify-content: center;
  margin: 48px auto;
}

.formRow {
  width: 100%;
  margin-bottom: 4px;

  @include Media.medium {
    display: flex;
    gap: 16px;
  }

  > * {
    flex: 1;
  }
}

.formRow + h2 {
  margin-top: 32px;
}

.formRow + .buttonBar {
  margin-top: 16px;
}

.errorActionLink {
  text-decoration: underline !important;
}

.paymentPlanList {
  margin: 0;
  padding-left: 14px;

  li {
    list-style-type: '•';
    padding-left: 6px;
  }
}


.paymentAccountCalloutButtons {
  position: relative;

  @include Media.medium {
    display: flex;
    gap: 8px;
  }

  .paymentCalloutBadge {
    align-self: center;

    @include Media.lt-medium {
      position: absolute;
      left: 0;
      transform: translate(calc(-100% - 8px), calc(50% - 4px));
    }
  }
}

.signature {
  @include Mixin.signature();
  border-radius: 4px;
  border: 1px solid Color.$gainsboro;
  background: Color.$smoke;
  height: 64px;
  display: flex;
  align-items: center;
  padding: 8px;
}

.termsButton {
  margin-top: 16px;
}

.alertBar {
  max-width: 560px;
}

    