
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.section {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    margin: 24px 0;
  }
}
    