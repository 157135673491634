
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

$side-padding: 16px;

.content {
    max-width: 560px + (2 * $side-padding);
    margin: 0 auto;
    padding: 32px $side-padding;

    @include Media.medium {
        padding-top: 64px;
    }

    > * {
        margin-top: 8px;
    }
}

.icon {
    display: block;
    margin: 0 auto 24px;
}


    