
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.wrapper {
  display: flex;
  height: 100%;
}

.contents {
  background-color: Color.$snow;
  width: 100%;
  transition: max-width 200ms;
  display: flex;
  flex-direction: column;

  @include Media.medium {
    border-radius: 8px;
    max-height: calc(100vh - 96px);
    min-width: 480px;

    &.small {
      max-width: 480px;
    }

    &.large {
      max-width: 960px;
    }
  }

  &.dark {
    background-color: Color.$shadow;
  }
}

.indicator {
  @include Media.lt-medium {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
}

    