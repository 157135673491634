@use 'sass:color';

// Todo: Have this generated by CommonColors
$surfie: #0A8577;
$surfie-dark: #086A5F;
$surfie-darker: #065047;
$surfie-lightest: #E0F9F6;
$sea: #1EA8A5;
$sea-lighter: #60E3E0;
$sea-lightest: #E5FAFA;
$eclipse: #404040;
$slate: #777777;
$gainsboro: #D9D9D9;
$cloud: #FAFAFA;
$snow: #FFFFFF;
$punch: #9D3535;
$punch-lightest: #F7E9E9;
$jaffa: #DD7B46;
$smoke: #F0F0F0;
$shadow: #000000;
$ivory: #FFFEF1;
$pearl: #FEFBE8;

@function scale-active($color) {
  @return color.scale($color, $lightness: -40%);
}

@function scale-hover($color) {
  @return color.scale($color, $lightness: -20%);
}

// TODO (ENG-1790): Compute scaled colors instead of assigning color directly.
$snow-active: $smoke;
$snow-hover: $cloud;
$surfie-active: scale-active($surfie);
$surfie-hover: scale-hover($surfie);
$punch-active: scale-active($punch);
$punch-hover: scale-hover($punch);
