
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.section {
  @include Media.lt-medium {
    cursor: pointer;
  }
}

.titleText {
  @include Mixin.text-ellipsis();
  font-weight: bold;
}

.toggle {
  @include Media.lt-medium {
    margin-top: 2px;
    display: flex;
  }

  @include Media.medium {
    display: none;
  }
}

.list {
  display: flex;

  @include Media.lt-medium {
    flex-direction: column;
  }

  @include Media.medium {
    flex-direction: row;
    gap: 16px;
  }
}

.wrapper {
  @include Media.lt-medium {
    display: flex;
    flex-direction: row;
    margin-bottom: 4px;
  }

  @include Media.medium {
    width: 200px;
    flex-grow: 1;
  }
}

.addressDetailsHidden {
  @include Media.lt-medium {
    display: none;
  }
}

.content {
  @include Media.lt-medium {
    justify-content: space-between;
    flex-direction: row;
    display: flex;
  }
}

.title {
  @include Media.lt-medium {
    width: 96px;
    flex-shrink: 0;
  }
}

    