
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

@use 'sass:color';

.section {
  margin: 16px 0;
  display: flex;
  border: 1px solid Color.$punch;
  background-color: color.adjust(Color.$punch, $alpha: -0.9);
  padding: 16px;

  ul {
    margin: 0;
    padding: 0;
    list-style-position: inside;
  }
}

.image {
  height: 46px;
  width: 54px;
  margin-right: 16px;
}

    