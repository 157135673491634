
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.tag {
  background-color: Color.$smoke;
  border-radius: 10px;
  display: inline-flex;
  flex-shrink: 0;
  vertical-align: top;
}

.label {
  @include Mixin.text-ellipsis();
  display: inline-flex;
  align-items: center;
  padding: 2px 10px 2px 4px;
  border-radius: 10px;
  font-weight: 600;
  white-space: nowrap;

  svg {
    margin-right: 5px;
    flex-shrink: 0;
  }

  small {
    @include Mixin.text-ellipsis();
  }

  &.noIcon {
    padding: 2px 8px;
  }
}

.removeButton {
  border-radius: 0 10px 10px 0;
  padding: 2px 4px 2px 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tag.removeable {
  .label {
    padding: 2px 0 2px 4px;
    border-radius: 10px 0 0 10px;
  }
}

.tag.removeable.withLeftLabel .label {
  border-radius: 0;
}

.label,
.removeButton {
  &[role=button]:hover,
  &:global(.isFocused) {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), Color.$smoke;
  }

  &[role=button]:active {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), Color.$smoke;;
  }
}

@keyframes loading {
  from {
    background-color: Color.$smoke;
  }

  to {
    background-color: Color.$gainsboro;
  }
}

.loading {
  width: 96px;
  height: 20px;
  background-color: Color.$smoke;
  animation: loading 1.5s infinite alternate ease-in-out;
  vertical-align: top;
}

.leftLabel {
  display: inline-flex;
  align-items: center;
  color: Color.$snow;
  padding: 0 4px 0 6px;
  border-radius: 10px 0 0 10px;
  font-size: 10px;

  &.warning {
    background-color: Color.$punch;
  }
}

    