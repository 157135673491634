
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

@keyframes spin {
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg);}
}

.spinner {
  height: 20px;
  width: 20px;
  animation: spin 0.66s infinite linear;

  &.large {
    height: 40px;
    width: 40px;
  }

  &.extra-large {
    height: 100px;
    width: 100px;
  }
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

    