
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  width: 100%;
}

.text {
  @include Mixin.text-ellipsis();
  color: Color.$slate;
}

.icon {
  margin-right: 5px;
}

.msgDate {
  @include Media.lt-small {
    display: none;
  }
}

.discountedText {
  color: Color.$sea;
}

.feeText {
  color: Color.$punch;
}

    