
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.stripeElement {
  border: 1px solid Color.$gainsboro;
  border-radius: 4px;
  padding: 7px 8px;
  width: 100%;
  transition: border-color 200ms;
  
  &:global(.StripeElement--focus) {
    outline: Color.$surfie-dark solid 2px;
    outline-offset: -1px;
  }
}

.row {
  display: flex;

  > * {
    flex-grow: 1;
  }

  .spacer {
    width: 16px;
    flex-grow: 0;
  }
}

    