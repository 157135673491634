
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.button {
  margin-top: 8px;
}

.alertBar {
  margin-bottom: 24px;
}

.content {
  margin-top: 24px;
  max-width: 560px;
}

.separatedSummary {
  margin-bottom: 16px;
}

.sectionWrapper {
  padding: 16px;
  background-color: Color.$cloud;
  border: 1px solid Color.$smoke;
}

.section + .section::before {
  content: '';
  display: block;
  height: 1px;
  margin-top: 16px;
  padding-bottom: 16px;
  border-top: 1px solid Color.$smoke;
}

.sectionBody {
  @include Media.medium {
    display: flex;
  }
}

.sectionIcon {
  width: 96px;
  margin: 0 auto;

  @include Media.medium {
    margin-left: 8px;
    margin-right: 24px;
  }
}

.sectionContent {
  flex-grow: 9999;

  @include Media.lt-medium {
    h3 {
      text-align: center;
    }
  }
}

.iconContainer {
  max-width: 560px;

  .iconCircle {
    margin: 0 auto 24px;
    width: 160px;
    height: 160px;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: Color.$surfie-lightest;
    border-radius: 50%;
  }

  svg {
    overflow: visible;
  }
}

    