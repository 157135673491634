
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

$toggle-desktop-font-size: 12;
$toggle-mobile-font-size: 16;

.wrapper {
  display: flex;
  align-items: center;
}

.toggleWrapper {
  position: relative;
}

.input {
  box-sizing: border-box;
  position: absolute;
  pointer-events: none;
  opacity: 0;
  z-index: -1;
}

.toggle {
  position: relative;
  display: inline-block;
  width: 3em;
  height: 2em;
  background-color: Color.$snow;
  border-radius: 1em;
  border: 1px solid Color.$gainsboro;
  vertical-align: middle;
  cursor: pointer;
  transition: background-color 100ms, border 100ms;

  font-size: $toggle-desktop-font-size * 1px;
  @include Media.lt-medium {
    font-size: $toggle-mobile-font-size * 1px;
  }

  &::before {
    content: '';
    display: block;
    border-radius: 50%;
    background-color: Color.$slate;
    position: absolute;
    top: 1px;
    left: 1px;
    transition: transform 100ms, background-color 100ms;
    box-shadow: 0 0.125em 0.25em rgba(0, 0, 0, 0.25);

    height: calc(20 / $toggle-desktop-font-size) * 1em;
    width: calc(20 / $toggle-desktop-font-size) * 1em;
    @include Media.lt-medium {
      height: calc(28 / $toggle-mobile-font-size) * 1em;
      width: calc(28 / $toggle-mobile-font-size) * 1em;
    }
  }

  .input:checked ~ &::before {
    background-color: Color.$snow;
    transform: translateX(1em);
  }

  .input:focus-visible ~ & {
    outline: Color.$surfie-dark solid 2px;
    outline-offset: 3px;
  }

  .input:checked ~ & {
    background-color: Color.$surfie;
    border: 1px solid Color.$surfie-dark;
  }
}

.label {
  margin-left: 14px;
}

.wrapper.disabled {
  .toggle {
    opacity: 0.33;
    cursor: default;
  }
}

    