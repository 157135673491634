
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.wrapper {
  position: relative;
}

.input {
  height: 32px;
  line-height: 32px;
  border: 1px solid Color.$gainsboro;
  border-radius: 4px;
  padding: 0 8px;
  width: 100%;
  transition: border-color 200ms;
  color: Color.$eclipse;

  &:focus {
    outline-offset: -1px;
  }

  &.invalid {
    border-color: Color.$punch;

    ~ * .icon {
      color: Color.$punch;
    }
  }

  &:disabled {
    background-color: Color.$smoke;

    ~ * .icon {
      color: Color.$slate;
    }
  }

  &.hasIcon {
    padding-left: 32px;
  }
}

.iconWrapper {
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  top: 0;
  left: 0;
}

.icon {
  width: 24px;
  height: 24px;
}

    