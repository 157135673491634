
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.qboSelected {
  background-color: Color.$surfie;
  color: Color.$snow;
}

.qboUnselected {
  background-color: Color.$snow;
}

.button {
  border: 0;
  background: none;
  padding: 0;
}

    