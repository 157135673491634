
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.ledger {
  background-color: Color.$cloud;
  border: 1px solid Color.$smoke;
  border-radius: 4px;
}

.label {
  flex-shrink: 99;
  min-width: 0;
}

.message {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  border-top: 1px solid Color.$smoke;

  &:first-child {
    border-top: 0;
  }
}

.bold {
  font-weight: bold;
}

.discount {
  color: Color.$sea;
}

.fee {
  color: Color.$punch;
}

.icon {
  margin-right: 5px;
}

.badge {
  margin-left: 8px;
}
    