
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

// In order to simplify the css, use the desktop padding for the left/right and animations
// since an additional 8 pixels will not be noticable when things are animated in and out

.step {
  top: 0;
  position: absolute;
  width: 100%;
  bottom: 0;
  display: flex;
  flex-direction: column;

  &.right {
    transform: translateX(100%);
  }

  &.left {
    transform: translateX(-100%);
  }
}

  .titleIcon {
    display: block;
    margin: 0 auto 16px;
  }


.title {
  padding-bottom: 8px;
}

.enter,
.appear {
  transition: transform 300ms cubic-bezier(0.25, 1, 0.5, 1);
  transform: translateX(100%);

  &.right {
    transform: translateX(100%);
  }

  &.left {
    transform: translateX(-100%);
  }
}

.enterActive {
  transform: translateX(0);
  transition: transform 300ms cubic-bezier(0.25, 1, 0.5, 1);
}

.appearActive {
  transform: translateX(0);
  transition: transform 300ms cubic-bezier(0.25, 1, 0.5, 1);
}

.enterDone,
.appearDone {
  @include Mixin.no-print();
  transform: translateX(0);
}

.exit {
  transform: translateX(0);
  transition: transform 300ms cubic-bezier(0.25, 1, 0.5, 1);
}

.exitActive {
  transition: transform 300ms cubic-bezier(0.25, 1, 0.5, 1);

  &.right {
    transform: translateX(100%);
  }

  &.left {
    transform: translateX(-100%);
  }
}

.exitDone {
  &.right {
    transform: translateX(100%);
  }

  &.left {
    transform: translateX(-100%);
  }
}

.icon {
  display: block;
  margin: 0 auto 16px;
}
    