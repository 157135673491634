
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.buttons {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 24px;

  padding-bottom: 16px;

  @include Media.medium {
    padding-bottom: 0;
  }
}

.searchBox {
  margin: 16px 0;
}

.searchResult {
  padding: 16px 0;
  cursor: pointer;
  border-top: 1px solid Color.$gainsboro;

  .searchName {
    color: Color.$surfie;
  }

  small {
    display: block;
  }

  &:first-child {
    border-top: 0;
  }
}

.searchFooter {
  color: Color.$slate;
}

.confirmComparison {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 16px;
  margin: 16px 0 8px;

  @include Media.medium {
    grid-template-columns: repeat(2, 1fr);
  }

  h3 {
    display: block;
    margin-bottom: 8px;
  }
}
    