
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.cell {
    padding: 8px;
    display: grid;
    text-decoration: none;
    align-items: center;

    /* stylelint-disable-next-line selector-no-qualifying-type */
    table :global(.leftShadow) & {
        padding-left: 14px;
    }
}
    