
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.section {
  max-width: 560px;
}

.toast {
  display: flex;
  align-items: center;

  svg {
    fill: Color.$surfie;
    margin-right: 10px;
  }
}

.alert {
  margin: 12px 0;
}

.alertText {
  color: Color.$punch;
}

.editableTitle {
  display: flex;
  max-width: 560px;
  align-items: center;
  @include Media.lt-medium {
    flex-wrap: wrap;
  }
}

.editableTitleAction {
  justify-self: flex-end;
  margin-left: auto;
  font-size: 14px;

  @include Media.lt-medium {
    flex-basis: 100%;
    margin-top: 4px;
  }
}

.alertAction {
  color: Color.$punch !important;
  text-decoration: underline;
}

    