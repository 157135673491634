
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.stepContent {
  padding: 32px;
}

.text {
  margin-bottom: 16px;
  text-align: center;

  .username {
    @include Mixin.text-ellipsis();
  }
}

.hide {
  display: none;
}

.footerText {
  margin-top: 24px;
}

.alreadySignedIn {
  display: flex;
  justify-content: space-between;
  font-weight: normal;

  .alreadySignedInAction {
    flex-shrink: 0;
  }
}

    