
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.label {
  &.noValue {
    color: Color.$surfie;
    font-weight: bold;
  }

  > * {
    vertical-align: middle;
  }

  svg {
    margin-left: 8px;
    fill: Color.$surfie;
  }
}


    