
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.box {
  max-width: 788px;
}

.feeText {
  margin-left: 8px;
}

.sellerFee {
  margin-top: 16px;
}

.select {
  display: inline-block;
  width: 100px;
  margin-top: 4px;
}

    