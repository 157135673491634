
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.search {
  border-radius: 16px;

  &::-webkit-search-cancel-button {

    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-appearance: none;
  
    height: 24px;
    width: 24px;
    display: block;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMiAyMUMxNi45NzA2IDIxIDIxIDE2Ljk3MDYgMjEgMTJDMjEgNy4wMjk0NCAxNi45NzA2IDMgMTIgM0M3LjAyOTQ0IDMgMyA3LjAyOTQ0IDMgMTJDMyAxNi45NzA2IDcuMDI5NDQgMjEgMTIgMjFaTTE1LjcwNzEgOC4yOTI4OUMxNi4wOTc2IDguNjgzNDIgMTYuMDk3NiA5LjMxNjU4IDE1LjcwNzEgOS43MDcxMUwxMy40MTQyIDEyTDE1LjcwNzEgMTQuMjkyOUMxNi4wOTc2IDE0LjY4MzQgMTYuMDk3NiAxNS4zMTY2IDE1LjcwNzEgMTUuNzA3MUMxNS4zMTY2IDE2LjA5NzYgMTQuNjgzNCAxNi4wOTc2IDE0LjI5MjkgMTUuNzA3MUwxMiAxMy40MTQyTDkuNzA3MTEgMTUuNzA3MUM5LjMxNjU4IDE2LjA5NzYgOC42ODM0MiAxNi4wOTc2IDguMjkyODkgMTUuNzA3MUM3LjkwMjM3IDE1LjMxNjYgNy45MDIzNyAxNC42ODM0IDguMjkyODkgMTQuMjkyOUwxMC41ODU4IDEyTDguMjkyODkgOS43MDcxMUM3LjkwMjM3IDkuMzE2NTggNy45MDIzNyA4LjY4MzQyIDguMjkyODkgOC4yOTI4OUM4LjY4MzQyIDcuOTAyMzcgOS4zMTY1OCA3LjkwMjM3IDkuNzA3MTEgOC4yOTI4OUwxMiAxMC41ODU4TDE0LjI5MjkgOC4yOTI4OUMxNC42ODM0IDcuOTAyMzcgMTUuMzE2NiA3LjkwMjM3IDE1LjcwNzEgOC4yOTI4OVoiIGZpbGw9IiM0MDQwNDAiLz4KPC9zdmc+Cg==');
    background-repeat: no-repeat;
    background-size: 24px;
    background-position: center;
  }
}

    