
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.warning {
  background-color: fade-out($color: Color.$jaffa, $amount: 0.9);
  border: 1px solid fade-out($color: Color.$jaffa, $amount: 0.6);
  margin-bottom: 16px;
  padding: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.warningIcon {
  margin-right: 12px;
  fill: Color.$jaffa;
  width: 24px;
  height: 24px;
  vertical-align: middle;
  flex-shrink: 0;
}

    