
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.indicator {
  padding: 8px;
  min-width: 224px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: Color.$punch;
  background-color: Color.$punch-lightest;

  &.active {
    color: Color.$surfie;
    background-color: Color.$surfie-lightest;
  }
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

    