
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.container {
  padding: 8px;
  border-color: Color.$gainsboro;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-top-style: solid;
  border-bottom-style: solid;
  margin-top: 16px;
  margin-bottom: 16px;

  > * {
    opacity: 0;
    transition: opacity 500ms cubic-bezier(0.55, 0, 1, 0.45); // easeInCirc
  }

  &.fadeIn > * {
    opacity: 1;
  }

  &.fadeOut > * {
    opacity: 0;
  }
}

.text,
.attribution {
  line-height: 22px;
  color: Color.$eclipse;
  text-align: center;
}

.attribution {
  font-style: italic;
}

    