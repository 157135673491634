
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.titleCell {
  width: 100%;
  height: 56px;
  user-select: none;
  border: 0;
  border-radius: 0;
  background-color: transparent;
  padding: 0;
  position: relative;
  color: Color.$eclipse;

  .title {
    font-weight: bold;
  }

  .description {
    font-size: 12px;
  }

  &:hover,
  &[data-open] {
    &::before {
      display: block;
    }
  }

  &::before {
    content: '';
    position: absolute;
    inset: 4px;
    background-color: Color.$cloud;
    border-radius: 4px;
    display: none;
    z-index: 0;
  }
}

.cellContent {
  z-index: 1;
  position: relative;

  .mainLine {
    line-height: 1.4;
  }
}

.textAlign-right {
  justify-self: flex-end;
  text-align: right;

  .mainLine {
    justify-content: flex-end;
  }

  .description {
    text-align: right;
  }

  .titleSort {
    padding-left: 20px;
    position: relative;
  }

  .sortArrow {
    position: absolute;
    left: 0;
  }
}

.textAlign-center {
  justify-self: center;
  text-align: center;

  .mainLine {
    justify-content: center;
  }

  .description {
    text-align: center;
  }
}

.textAlign-left {
  .sortArrow.inactive {
    opacity: 1;
    width: 16px;
    margin-right: 4px;
  }
}

.titleSort {
  display: flex;
  align-items: center;
}

.sortArrow {
  transition: opacity 200ms, color 200ms, background-color 200ms, width 200ms, margin-right 200ms;
  width: 16px;
  height: 16px;
  text-align: center;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin-right: 4px;

  &:hover {
    background-color: Color.$smoke;
  }

  &.inactive {
    opacity: 0;
    width: 0;
    color: Color.$gainsboro;
    margin-right: 0;

    &:hover {
      color: Color.$slate;
    }
  }

  .titleCell:hover &.inactive {
    opacity: 1;
    width: 16px;
    margin-right: 4px;
  }
}

.dropdownCaret {
  font-size: 16px;
}

.mainLine {
  display: flex;
  align-items: center;
  gap: 4px;
}

.description {
  text-align: left;
}

.menuItems {
  z-index: Var.$dropdown-zindex;
  --anchor-gap: 6px;
  background-color: Color.$snow;
  border-radius: 4px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.25);
  width: 200px;
  padding: 4px 0;
}

.menuSep {
  height: 1px;
  background-color: Color.$gainsboro;
  margin: 4px 8px;
}

.menuActions {
  display: flex;
  margin: 8px;
  justify-content: space-evenly;

  > * {
    border: 0;
    background-color: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    color: Color.$surfie;
    border-radius: 4px;
    font-size: 16px;

    &:hover {
      color: Color.$surfie-dark;
      background-color: Color.$surfie-lightest;
    }

    &.trash {
      color: Color.$punch;

      &:hover {
        background-color: Color.$punch-lightest;
      }
    }
  }
}

.menuItem {
  background-color: inherit;
  border: 0;
  display: flex;
  gap: 8px;
  padding: 12px 16px;
  color: Color.$surfie;
  font-weight: bold;
  width: 100%;

  &:hover {
    background-color: Color.$smoke;
    color: Color.$surfie-dark;
  }

  &.remove {
    color: Color.$punch;

    &:hover {
      background-color: Color.$punch-lightest;
    }

    &[data-disabled] {
      color: Color.$slate;

      &:hover {
        background-color: inherit;
      }
    }
  }
}

.menuItemInTooltip {
  display: flex;
  gap: 8px;
}

/* stylelint-disable */
:global(.is-resizing) table {
  .titleCell:hover {
    &::before {
      display: none;
    }

    &:not(.textAlign-left) {
      .sortArrow.inactive {
        opacity: 0;
        width: 0;
        margin-right: 0;
      }
    }
  }

  .title {
    white-space: nowrap;
  }

  .sortArrow {
    &:hover {
      background-color: transparent;
    }

    &.inactive {
      &:hover {
        color: Color.$gainsboro;
      }
    }
  }
}
/* stylelint-enable */
    