
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.columns {
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  @include Media.medium {
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
  }
}

.columnItem { 
  @include Media.lt-medium {
    display: flex;

    > * {
      width: 50%;
    }
  }
}

.fallback {
  color: Color.$slate;
}

    