
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.content {
  margin: 32px 0;
  max-width: 640px;
}

.columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.config {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.logging {
  flex-shrink: 9999;
}

.actions {
  display: flex;
  gap: 8px
}

.templateSelect {
  max-width: 320px;
}

    