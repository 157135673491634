
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.window {
  background-color: Color.$snow;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.25);
  width: 448px;
  margin: 0 16px 16px auto;
  display: flex;
  flex-direction: column;
}

.header {
  padding: 8px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
}

.notesContainer {
  position: relative;
}

.notes {
  margin: 0 16px;
  min-height: 112px;
  max-height: 248px;

  &:global(.scrollable):read-only::before {
    content: '';
    position: absolute;
    top: -2px;
    left: 0;
    right: 0;
    height: 2px;
    transition: box-shadow 200ms;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    clip-path: xywh(0 2px 100% 6px);

  }

  &:global(.scrollable):read-only::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 1px;
    transition: box-shadow 200ms;
    box-shadow: 0 -1px 1px 0 Color.$gainsboro;
  }

  &:global(.scrollable.scrolledTop)::before,
  &:global(.scrollable.scrolledBottom)::after {
    box-shadow: none;
  }
}

.footer {
  padding: 16px;
}
    