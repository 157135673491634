
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.pagination {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
}

.topPaginationLabel {
  padding-left: 4px;
}

.loading {
  opacity: 0.5;
  pointer-events: none;
}

.scrollable {
  overflow-x: auto;
}

.noItems {
  text-align: center;
  margin-top: 32px;
}

.noItemsText {
  margin-top: 16px;
}

    