
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.general {
  border-bottom: 1px solid Color.$gainsboro;

  &:has(+ .errorRow) {
    padding-bottom: 4px;
    border-bottom: 0;
  }
}

.errorRow {
  padding: 0 12px 4px 0;
  border-bottom: 1px solid Color.$gainsboro;
  color: Color.$punch;
}

.error {
  min-height: 22px;
  white-space: nowrap;
  min-width: 100%;
}

.loadingIndicator {
  display: inline-flex;
  vertical-align: text-top;
  margin-right: 4px;
}

.editButtonCell > span {
  overflow: visible !important;
}

.editButtons {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-bottom: 12px;

  @include Media.lt-medium {
    flex-direction: column;
    align-items: flex-end;
    flex-basis: 100%;
  }
}

.editButtonRow {
  border-bottom: 0;
}

.editError, .editInfo {
  text-align: right;
}

.editError {
  color: Color.$punch;
}

    