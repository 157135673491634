
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.text {
  text-align: center;

  & + & {
    margin-top: 24px;
  }
}

.icon {
  width: 64px;
  height: 64px;
  border-radius: 32px;
  margin: 0 auto 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.secondButton {
  margin-top: 8px;
}

.body {
  > * + * {
    margin-top: 24px;
  }
}

    