
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.checkbox {
  margin: 8px 0 8px -4px;
  display: flex;
  align-items: center;
}

.optedOut {
  display: block;
}

    