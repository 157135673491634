
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

$header-height: 48px;

.header {
  position: relative;
  height: $header-height;
  padding: 0 $header-height;
  text-align: center;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &.dark {
    color: Color.$snow;
  }
}

.close {
  position: absolute;
  top: 4px;
  left: 4px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  transition: opacity 300ms cubic-bezier(0.25, 1, 0.5, 1);

  @include Media.medium {
    left: 12px;
  }
}

.transitionGroup {
  min-width: 0;
}

.title {
  @include Mixin.text-ellipsis();
  transition: opacity 300ms cubic-bezier(0.25, 1, 0.5, 1);
}

.enter,
.appear {
  opacity: 0;

}

.enterActive {
  opacity: 1;
}

.appearActive {
  opacity: 1;
}

.enterDone,
.appearDone {
  opacity: 1;
}

.exit {
  opacity: 1;
}

.exitActive {
  opacity: 0;
}

.exitDone {
  opacity: 0;
}
    