
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.header {
  @include Media.medium {
    @include Mixin.sticky-header(Color.$gainsboro, Color.$snow);
  }
}

.searchBar {
  margin-bottom: 16px;
}

.filterBar {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  >:first-child {
    width: 100%;
  }

  .clearButton {
    margin-bottom: 12px;
  }

  .checkbox {
    margin-bottom: 16px;
  }

  @include Media.medium {
    flex-direction: row;
    align-items: flex-end;
    gap: 16px;

    >:first-child {
      flex-grow: 1;
      width: auto;
    }
  }

  :global(.react-select__value-container) {
    flex-wrap: nowrap;
    mask-image: linear-gradient(90deg, rgba(0, 0, 0, 1) 95%, transparent 100%);
    height: 30px;
  }
}

.actionLink {
  margin-bottom: 16px;
}

    