
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.teaser {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  position: sticky;
  top: 0;
  justify-content: center;
  padding: 0 16px;
  max-height: 100%;
  width: 144px;

  color: Color.$surfie;
  line-height: 22px;
  font-weight: 700;
  text-align: center;
  margin: 8px;
  cursor: pointer;

  &:hover {
    background-color: Color.$cloud;
  }
}

.button {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  text-align: center;
}

    