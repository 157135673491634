
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.option {
  &:global(.react-select__option--is-focused) {
    .innerOption {
      background-color: Color.$snow-hover;

      &:hover {
        background-color: Color.$snow-hover;
      }

      &:active {
        background-color: Color.$snow-active;
      }
    }

  }

  &:global(.react-select__option--is-selected) {
    .innerOption {
      background-color: Color.$surfie;
      color: Color.$snow;

      &:hover {
        background-color: Color.$surfie-hover;
      }

      &:active {
        background-color: Color.$surfie-active;
      }
    }
  }
}

.innerOption {
  display: flex;
  flex-direction: column;
  padding: 12px 16px;

  color: Color.$surfie;
  cursor: pointer;
}

    