
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.companySwitcher {
  @include Mixin.no-print();
  padding: 8px 16px;
  border-bottom: 1px solid Color.$gainsboro;
  background-color: Color.$snow;
  display: flex;
  justify-content: space-between;

  @include Media.medium {
    display: none;
  }
}

.pageContent {
  @include Media.medium {
    margin-left: Var.$sidenav-width;
    max-width: Var.$desktop-content-max-width;

    @media print {
      margin-left: 0;
    }
  }
}

.content {
  padding: 16px;

  @include Media.medium {
    padding: Var.$screen-frame-padding-desktop;
  }

  @media print {
    padding: 0;
  }
}

.fixedFooter {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
  pointer-events: none;

  &::after {
    content: '';
    display: block;
    height: env(safe-area-inset-bottom);
    background-color: Color.$cloud;
  }

  @include Media.medium {
    left: Var.$sidenav-width;

    @media print {
      left: 0;
    }
  }  
}

.fixedFooterSpacer {
  pointer-events: none;

  > * {
    pointer-events: auto;
  }
}

.toast {
  position: absolute;
  left: 16px;
  right: 16px;
  transform: translateY(calc(-100% - 20px));

  @include Media.medium {
    left: 32px;
    right: 32px;
  }
}

    