
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.exampleLink {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  svg {
    margin-left: 8px;
  }

  margin-top: 16px;
}

.sampleContainer {
  background: Color.$cloud;
  border: 1px solid Color.$smoke;
  border-radius: 4px;
  padding: 8px;
}

.skipPrompt {
  margin: 16px 0;
}
    