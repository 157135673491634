
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

:global(.ReactModal__Body--open) {
  width: 100%
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 300ms ease-in-out;
  z-index: Var.$modal-zindex;

  @include Media.medium {
    background-color: rgba(0, 0, 0, 0.6);
  }

  &.afterOpen {
    opacity: 1;
  }

  &.beforeClose {
    opacity: 0;
  }
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translate(0, 25%);
  opacity: 0;
  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
  outline: none !important;

  @include Media.medium {
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -25%);
  }

  &.afterOpen {
    transform: translate(0, 0);
    opacity: 1;

    @include Media.medium {
      transform: translate(-50%, -50%);
    }
  }

  &.beforeClose {
    transform: translate(0, 25%);
    opacity: 0;

    @include Media.medium {
      transform: translate(-50%, -25%);
    }
  }
}

    