
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.button {
  flex-direction: column;

  &.width-auto {
    width: 100%;
    max-width: 560px;
  }

  &.width-content {
    width: fit-content;
    max-width: 100%;
  }

  &.width-full {
    width: 100%;
    max-width: 100%;
  }

  &.loading {
    flex-direction: row;
  }
}

.loader {
  height: 24px;

  @include Media.medium {
    height: 22px;
  }

  &.double {
    height: 48px;

    @include Media.medium {
      height: 44px;
    }
  }
}

.loadingText {
  margin-left: 8px;
}

.text {
  @include Mixin.text-ellipsis();
  max-width: 100%;
}

.icon {
  margin-left: 4px;
  vertical-align: middle;
}

    