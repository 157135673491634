
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.sidebar {
  @include Mixin.no-print();
  background-color: Color.$cloud;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: Var.$sidenav-width;
  border-right: 1px solid Color.$smoke;
  padding: 16px 0;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: Var.$nav-zindex;

  @include Media.lt-medium {
    display: none;
  }
}

.content {
  padding: 0 8px;
}
    