
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.content {
  max-width: 640px;
}

.headerImage {
  display: flex;
  justify-content: center;
  border-bottom: 0.5px solid Color.$gainsboro;

  @include Media.lt-medium {
    border-bottom: unset;
    display: block;
    font-size: 0;

    &::after {
      content: "";
      width: calc(100% + 32px);
      position: relative;
      left: -16px;
      height: 0.5px;
      display: block;
      background-color: Color.$gainsboro;
    }

    svg {
      width: 100%;
      height: auto;
    }
  }
}

.header {
  h1 {
    margin-top: 32px;
    margin-bottom: 24px;
  }
}

.stickyButton {
  position: sticky;
  top: 0;
  padding: 24px 0 32px;
  background-color: Color.$snow;
  border-bottom: 1px solid Color.$smoke;

  display: flex;
  gap: 8px;

  @include Media.lt-medium {
    flex-direction: column;
  }
}

.bookButton {
  max-width: 640px !important;
}

.keyPoints {
  display: flex;
  flex-direction: column;
  padding: 32px 0;
  gap: 48px;
  border-bottom: 1px solid Color.$smoke;

  @include Media.lt-medium {
    gap: 32px;
  }
}

.keyPoint {
  display: flex;
  gap: 32px;
  align-items: center;

  &.right {
    flex-direction: row-reverse;
  }

  .text {
    flex-shrink: 99;

    h3 {
      margin-bottom: 4px;
    }
  }

  @include Media.lt-medium {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    &.right {
      flex-direction: column;
    }
  }
}

.quote {
  display: flex;
  padding: 24px 0 36px;
  align-items: center;
  background-color: Color.$snow;

  & + & {
    border-top: 1px solid Color.$gainsboro;
  }

  .content {
    flex-shrink: 999;
    margin-left: 24px;
  }
}

.quoteImg {
  width: 64px;
  height: 64px;
  object-fit: contain;
}

.attribution {
  font-weight: bold;
}

.quotes {
  --swiper-pagination-bullet-inactive-color: #{Color.$gainsboro};
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-color: #{Color.$slate};
  --swiper-pagination-bottom: 0;
  --swiper-pagination-bullet-horizontal-gap: 8px;
  padding-top: 48px;
}
    