
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.radioButtons {
  > div:not(:last-child) {
    margin-bottom: 12px;
  }

  input {
    appearance: none;
    border: 2px solid Color.$eclipse;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    margin-right: 10px;
    position: relative;
    top: 2px;
  }

  input:checked {
    border: 2px solid Color.$eclipse;

    &::after {
      position: absolute;
      left: 1px;
      top: 1px;
      content: '';
      border-radius: 50%;
      background-color: Color.$surfie;
      width: 10px;
      height: 10px;
    }
  }

  .option {
    display: flex;
    align-items: center;
  }
}

    