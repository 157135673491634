
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

@use 'sass:color';

$table-sticky-cell-bg: color.scale(Color.$snow, $alpha: -5%);
$table-sticky-even-cell-bg: color.scale(Color.$cloud, $alpha: -5%);
$table-sticky-shadow-color: rgba(0, 0, 0, 0.66);

.table {
  /* box-shadow and borders will not work with positon: sticky otherwise */
  border-collapse: separate !important;
  border-spacing: 0;

  thead {
    position: sticky;
    top: 0;
    z-index: 2;

    th {
      background-color: $table-sticky-cell-bg;
      border-bottom: 1px solid Color.$gainsboro;
      font-weight: normal;
    }

    .pinned {
      z-index: 4;
    }
  }

  tfoot {
    position: sticky;
    bottom: 0;
    z-index: 2;

    th {
      background-color: $table-sticky-cell-bg;
      border-top: 1px solid Color.$gainsboro;
      font-weight: normal;
    }

    @media print {
      position: relative;
    }
  }

  td, th {
    text-align: left;
    min-height: 38px; // 40 - 2px for borders
    border: 0;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    padding: 0;
    position: relative;
    box-sizing: border-box;

    &:first-child {
      border-left: 1px solid transparent;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:last-child {
      border-right: 1px solid transparent;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  th {
    &:first-child {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }

    &:last-child {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
  }

  .pinned {
    background-color: $table-sticky-cell-bg;
    position: sticky;
    z-index: 1;
    transform: translateZ(0); // Fix for sticky issue on Safari

    &.lastLeft {
      &::after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        top: -1px;
        bottom: -1px;
        width: 1px;
        box-shadow: 0 0 6px $table-sticky-shadow-color;
        clip-path: polygon(0 0, 6px 0, 6px 100%, 0 100%);
      }
    }

    &.firstRight {
      box-shadow: 4px 0 4px -4px $table-sticky-shadow-color inset;
    }
  }

  .firstCenter {
    padding-left: 8px;
  }

/* stylelint-disable */
  tbody {
    tr:nth-of-type(2n) {
      td {
        background: Color.$cloud;
        border-top: 1px solid Color.$smoke;
        border-bottom: 1px solid Color.$smoke;

        &.pinned {
          background-color: $table-sticky-even-cell-bg;
        }

        &:first-child {
          border-left-color: Color.$smoke;
        }


        &:last-child {
          border-right-color: Color.$smoke;
        }
      }
    }
  }
/* stylelint-enable */

  tr:hover {
    [data-link],
    .pinned[data-link] {
      background-color: Color.$smoke;
    }

    &:nth-of-type(2n) [data-link] {
      border-color: Color.$gainsboro;
    }
  }
}

.resizer {
  cursor: col-resize;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  touch-action: none;
  user-select: none;
  width: 6px;
  border-radius: 4px;
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover,
  &.resizing {
    background-color: Color.$surfie;
  }

  &::after {
    content: '';
    position: absolute;
    display: none;
    width: 1px;
    background-color: Color.$surfie;
    top: 0;
    left: 3px;
    height: 100vh;
  }

  &.resizing::after {
    display: block;
  }

  > div {
    width: 2px;
    height: 12px;
    border-radius: 1px;
    background-color: Color.$snow;
    z-index: 12;
    display: none;
  }

  &:hover, &.resizing {
    > div {
      display: block;
    }
  }
}

.loading {
  opacity: 0.5;
  pointer-events: none;
}

.isEmpty {
  flex: 1;
}

/* stylelint-disable */
.table:not(.loading) {
  td.highlight, th.highlight {
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: '';
      animation: 1000ms linear 150ms glow;
      pointer-events: none;
    }
  }
}
/* stylelint-enable */

.noItems {
  text-align: center;
  margin-top: 32px;
}

.noItemsText {
  margin-top: 16px;
}

@keyframes glow {
  0% {
    background-color: rgba(Color.$surfie, 0.1);
  }

  50% {
    background-color: rgba(Color.$surfie, 0.1);
  }

  100% {
    background-color: transparent;
  }
}

    