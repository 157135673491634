
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.paidMessage {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 16px;
  padding: 10px;
}

.paidMessageText {
  margin-left: 5px;
  font-weight: bold;
  color: Color.$eclipse;
}

.invoiceContent {
  > * {
    border-top: 1px solid Color.$gainsboro;
    padding-top: 16px;
    margin: 16px 0;
  }

  @include Media.medium {
    min-width: 860px;
  }
}

.builderContent {
  padding-top: 8px;

  > * {
    margin: 8px 0;
  }
}

.invoiceSingleHeaderDetails {
  flex-direction: row;
}

.memo {
  white-space: pre-wrap;
}

.sendStatus {
  @include Mixin.no-print();
  display: flex;
}

.sendStatusLabel {
  margin-right: 6px;
}

.detailBadge {
  print-color-adjust: exact;
  margin-right: 8px;
}

.attachmentMessage {
  max-width: Var.$subcontent-max-width;
  margin-bottom: 8px;
}

.otherInfoLink {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  svg {
    margin-left: 8px;
  }
}

.attachments {
  margin-top: 16px;
}

    