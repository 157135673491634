
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.bulkActions {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;

  @include Media.medium {
    flex-direction: row;
    margin-top: 24px;
  }

  @media print {
    display: none;
  }
}

.selectAllBtn {
  margin: 0 0 12px;

  @include Media.medium {
    width: 364px;
    margin: 0 16px 0 0;
  }
}

.footerWarning {
  margin: 16px 0;
  font-style: italic;
}

.creditHold {
  margin-bottom: 16px;
}

    