
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.tabs {
  @include Mixin.no-print();
  border-top: 1px solid Color.$smoke;
  height: 48px;
  display: flex;
  justify-content: space-around;
  background-color: Color.$cloud;
  z-index: Var.$nav-zindex;
  position: relative;
  pointer-events: auto;

  @include Media.medium {
    display: none;
  }
}

    