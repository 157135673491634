
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.button {
  display: flex;
  align-items: center;
  color: inherit;
  text-decoration: none;
  background-color: Color.$cloud;
  padding: 8px 10px;

  @include Media.lt-medium {
    border-top: 1px solid Color.$smoke;
  }

  @include Media.medium {
    padding: 8px;
  }

  &.stale,
  &.warning {
    color: Color.$punch;
    font-weight: bold;

    @include Media.medium {
      padding: 8px 16px 8px 8px;
      border-radius: 4px;
    }
  }
}

.interactive {
  @include Media.medium {
    border-radius: 4px;

    &:hover {
      background-color: Color.$smoke;
    }

    &:active {
      background-color: Color.$gainsboro;
    }
  }
}

.text {
  flex-shrink: 99;
  margin-left: 12px;
}

    