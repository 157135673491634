
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.bullet {
  max-width: 560px;

  & + & {
    padding-top: 8px;
  }

  &.isEditing {
    & + & {
      padding-top: 16px;
    }
  }
}

.mainText {
  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;
    flex-shrink: 0;
    align-self: flex-start;
    margin-top: 3px;
  }
}

.subText {
  padding-left: 24px;
  max-width: 536px;
  line-height: calc(16 / 12);
  white-space: pre-wrap;
}

.includeIcon {
  color: Color.$surfie;
}

.excludeIcon {
  color: Color.$punch;
}

.toggle {
  max-width: 560px;

  @include Media.medium {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .toggleTitle {
    padding-right: 8px;;
  }

  .segment {
    margin-top: 4px;
    min-width: 200px;

    &.threeOptions {
      min-width: 300px;
    }

    @include Media.medium {
      margin-top: 0;
    }
  }
}

.input {
  margin-top: 8px;
}

    