
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.content {
  overflow: hidden;
  height: 0;
  transition: height 300ms cubic-bezier(0.65, 0, 0.35, 1); // easeInOutCubic
}

    