
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.button {
  margin-top: 8px;
}

.firstButton {
  margin-bottom: 8px;
}

.modalDescription {
  margin-bottom: 8px;
}

    