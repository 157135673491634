
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.container {
  margin: 0;

  >:first-child {
    margin-bottom: 8px;
  }
}

.text {
  word-break: break-word;
}

    