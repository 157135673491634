
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.general {
  border-bottom: 1px solid Color.$gainsboro;
}

.total {
  border-top: 2px solid Color.$gainsboro;
}

.totalCell {
  border-top: 1px solid Color.$gainsboro;
}

.checkbox {
  margin-right: 4px;
  align-self: center;

  &.bottomAlign {
    align-self: flex-end;
  }
}

.rowLink {
  text-decoration: none;
  color: inherit;
  display: block;
  border-radius: 2px;
  outline-offset: 0;

  @include Media.medium {
    &:hover {
      background-color: Color.$snow-hover;
    }

    &:active {
      background-color: Color.$snow-active;
    }
  }
}

.row {
  height: auto;
  padding: 12px 0;
  display: flex;

  @include Media.medium {
    padding: 8px Var.$hover-inset;
  }
}

.cellRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.rowContent {
  flex-grow: 999;
  justify-content: center;
  width: 1px;
}

.leftCell {
  width: 0;
  flex-grow: 999999;
}

.bottomCell {
  flex-basis: 100%;
  width: 100%;
}

.summaryRow {
  border-top: 1px solid Color.$gainsboro;

  .summaryTitle {
    white-space: nowrap;
  }
}

.loading {
  opacity: 0.5;
}

    