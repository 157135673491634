
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.paymentAccountTypeHeader {
  margin-top: 16px;
}

.paymentAccountAuthHeader {
  margin: 16px 0 8px;
}

.paymentAccountTypeDescription {
  margin-bottom: 8px;
}

.paymentPlanList {
  margin: 0;

  li {
    list-style-type: '•';
    padding-left: 6px;
  }
}

.feeConfig {
  line-height: calc(16 / 12);
}

.subtitle {
  font-weight: normal;
  font-size: 14px;
  max-width: 536px;
}

.hideActionLink {
    margin-top: 16px;
    display: flex;
    gap: 2px;
}

    