
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.buttons {
  display: flex;
  
  > * {
    flex-shrink: 1;
  }
}
  
.buttonSpacer {
  margin-left: 8px;
  margin-top: 8px;
}
  
.label {
  display: inline-block;
  cursor: pointer;
}

    