
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.list {
  margin-top: 16px;
  padding-top: 32px;
  border-top: 1px solid;
  border-color: Color.$gainsboro;
}

.list > * {
  margin-bottom: 8px;
}

.table {
  @include Media.medium {
    display: grid;
    grid-template-columns: 75% 25%;
  }
}
    