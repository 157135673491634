
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.companyCards {
  margin-top: 16px;
  display: grid;
  grid-template-columns: 1fr;

  @include Media.medium {
    grid-template-columns: 1fr 1fr 1fr;
  }
  gap: 16px;
}

.card {
  border-radius: 8px;
  padding: 16px;
  border: 1px solid var(--Smoke, #F0F0F0);
  background: Color.$snow;
  position: relative;
  display: flex;
  flex-direction: column;

  .badge {
    align-self: flex-end;
  }
}

.buttons {
  margin-top: 4px;
  display: flex;
  gap: 8px;
}

.validIcon {
  color: Color.$surfie;
}

.companyCards + h2 {
  margin-top: 32px;
}

    