
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

@use 'sass:color';

.container {
  @include Mixin.no-print();
  position: fixed;
  z-index: Var.$nav-zindex;

  &.hidden {
    display: none;
  }

  &.right {
    right: 0;
  }

  &.left {
    left: 0;
  }

  &.bottom {
    bottom: 0;
  }

  &.top {
    top: 0;
  }
}

.indicator {
  background-color: Color.$punch;
  color: Color.$snow;
  padding: 2px 12px;
  cursor: pointer;
}

.contentWrapper {
  max-height: 0;
  max-width: 0;
  transition: max-height 0.3s, max-width 0.3s;
  overflow: hidden;

  .open & {
    max-height: 400px;
    max-width: 450px;
  }
}

.content {
  background-color: color.scale(Color.$punch, $lightness: 80%);
  width: 100vw;
  max-width: 450px;
  overflow: auto;
  padding: 4px;
}

    