
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.section {
  padding: 0 4px;
}

.section + .section {
  margin-top: 32px;
}

.section:last-child {
  padding-bottom: 4px;
}

.items {
  margin-top: 8px;

  .button {
    margin-top: 16px;
  }
}

.bottomBorder::before {
  box-shadow: 0 0 5px rgb(0, 0, 0);
}

.paymentAccount {
  position: relative;
}

    