
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.status {
  display: flex;
  align-items: center;
  gap: 4px;

  &.incomplete {
    svg {
      color: Color.$jaffa;
    }
  }

  &.pending_link, &.accepted_and_linked, &.accepted {
    svg {
      color: Color.$surfie;
    }
  }

  &.needs_review {
    svg {
      color: Color.$slate;
    }
  }
}
    