
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.tagModal {
  padding-top: 8px;
}

.tag {
  margin-bottom: 18px;

  > label {
    display: flex;
  }

  b {
    @include Mixin.text-ellipsis();
    color: Color.$surfie;
    margin-left: 12px;
    flex-shrink: 99;
    padding-right: 8px;
  }

  .count {
    margin-left: auto;
    justify-self: flex-end;
  }
}

    