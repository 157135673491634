
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.cell {
  display: flex;
  align-items: center;

  @include Media.medium {
    padding-left: 4px;
    padding-right: 8px;
  }

  &.sortable > * {
    cursor: pointer;
  }

  &.bottomAlign {
    align-items: flex-end;
  }

  &.hasSubValue {
    flex-wrap: wrap;
  }

  .subValue {
    flex-basis: 100%;
    cursor: default;
  }
}

    