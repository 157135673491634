
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.menu {
  margin-left: 4px;
  position: relative;

  .buttonCaret {
    width: 16px;
    height: 16px;
    margin-left: 2px;
  }

  .menuTitle {
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
  }
}

.hitSlop {
  inset: -2px 0;
  position: absolute;
}

.popoverContainer {
  z-index: Var.$dropdown-zindex;
}

.popover {
  background-color: Color.$cloud;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 8px 0;
}

// Have to increase specificity due to ActionLink
.popover .menuItem {
  padding: 16px;
  display: block;
  width: 100%;

  &:hover {
    background-color: Color.$snow-active;
    color: Color.$surfie-active;
  }
}

    