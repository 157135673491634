
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.section {
  margin-bottom: 16px;
}

.actionButtons {
  * + * {
    margin-left: 16px;
  }
}

.contentContainer {
  @include Media.medium {
    margin-left: 48px;
  }
}

.stale {
  color: Color.$punch;
  font-weight: 600;
}

.reauthMessage {
  margin-bottom: 8px;
}

.icon {
  margin-right: 16px;
  width: 32px;
  display: flex;
}

.iconCircle {
  background-color: Color.$surfie;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.statusContainer {
  display: flex;
  align-items: center;
}

.textContainer {
  flex-shrink: 9999;
}

    