
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.user {
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;

  h2 {
    margin-bottom: 16px;
  }
}

.contactInfoSection {
  max-width: 560px;
}

.viewPreferencesLink {
  margin-bottom: 12px;
}

.phoneBadge {
  margin-left: 8px;
}

.headerText {
  font-weight: bold;
  margin-bottom: 8px;
}

.paymentAccountSelect {
  min-width: 240px;
}

.paymentAccountLabel > span {
  white-space: nowrap;
}

.notificationGroup:not(:last-child) {
  margin-bottom: 16px;
}

.notificationTitle {
  margin-bottom: 8px;
}

    