
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.mobilePaidWith {
  @include Mixin.text-ellipsis();
  display: inline-block;
  vertical-align: middle;
}

.badgePile {
  width: 132px;
}

.mobileBadge + .mobileBadge {
  display: inline-block;
  margin-top: 0;
  margin-left: 4px;
}

.expectedPayout {
  @include Mixin.text-ellipsis();
}

    