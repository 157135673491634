
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.header {
  border-bottom: 1px solid Color.$gainsboro;
  padding-bottom: 20px;

  p {
    max-width: 560px;
  }
}

    