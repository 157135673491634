
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.row {
  display: flex;
  align-items: center;

  > * {
    flex-grow: 99;

    + * {
      margin-left: 16px;
    }
  }

  .rightLabel {
    flex-grow: 1;
    font-size: 12px;
  }
}

    