
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.paymentMethods {
  @include Media.medium {
    display: flex;
    gap: 8px;
    align-items: center;
  }
}

.badgeStack {
  width: 170px;
}

.mobileBadges {
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
}

    