
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.filterBar {
  @include Media.medium {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-end;
    @include Mixin.sticky-header(Color.$gainsboro, Color.$snow);
  }

  > div:first-child,
  > div:nth-child(2) {
    flex-basis: 50%;
    max-width: 50%;
    flex-shrink: 1;
    flex-grow: 0;
    margin-right: 16px;

    @include Media.lt-medium {
      max-width: 100%;
      margin-right: 0;
    }
  }

  &.messageFilterEnabled {
    > div:first-child,
    > div:nth-child(2),
    > div:nth-child(3) {
      flex-basis: 33%;
      max-width: 33%;
      flex-shrink: 1;
      flex-grow: 0;
      margin-right: 16px;

      @include Media.lt-medium {
        max-width: 100%;
        margin-right: 0;
      }
    }
  }

  @include Media.lt-medium {
    > div:last-child {
      padding-bottom: 4px;
    }
  }
}

@include Media.lt-medium {
  .filterBar {
    display: block;

    > div:first-child,
    > div:nth-child(2),
    > div:nth-child(3) {
      margin-right: 0;
    }
  }
}

.select {
  width: 100%;
  display: inline-block;
  margin-right: 16px;
  vertical-align: middle;

  @include Media.lt-small {
    width: 100%;
    margin-right: 0;
  }
}

.title {
  margin: 0 0 8px;
}

.statusOption {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 54px;

  small {
    font-weight: normal;
  }
}

.filterBar :global(.react-select__single-value) {
  .statusOption {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: auto;
  }

  .statusMetadata {
    font-weight: 500;
    padding-left: 8px;
    display: none;

    &.hasMetadata {
      display: block;
    }
  }
}

.filter {
  @include Media.medium {
    margin-bottom: -12px;
  }
}

.filter + .filter {
  @include Media.lt-small {
    margin-top: 16px;
  }
}

.actionLink {
  align-self: flex-end;
  flex-shrink: 0;

  @include Media.lt-small {
    &.hideClear {
      display: none;
    }
  }

  @include Media.medium {
    .filterCount {
      display: none;
    }
  }

  @include Media.lt-medium {
    @include Mixin.sticky-mask(Color.$snow, 16px);

    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    background-color: Color.$snow;
    z-index: 1;
    top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid Color.$gainsboro;
  }
}

    