
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.menuLoading {
  display: flex;
  align-items: center;
  padding: 0 0 20px 16px;
}

.loadingLabel {
  margin-left: 8px;
}

.noOptionsText {
  text-align: left;
  color: Color.$slate;
}

    