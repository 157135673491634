
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.companyItem {
  min-height: 64px;
  border-bottom: 1px solid Color.$gainsboro;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttonWrapper {
  width: 100px;
  flex-shrink: 0;
}

.companyName {
  padding: 12px 16px 12px 0;
}

.error {
  color: Color.$punch;
  font-weight: bold;
}

    