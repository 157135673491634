
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.modalBody {
  text-align: center;
}

.circle {
  color: Color.$snow;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px auto 16px;
}

.bottomMessage {
  margin-top: 8px;
}

.firstButton {
  margin-top: 16px;
  margin-bottom: 8px;
}

    