
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.section {
  padding: 0 4px;
}

.section + .section {
  margin-top: 32px;
}

.section:last-child {
  padding-bottom: 4px;
}

.items {
  margin-top: 8px;

  .button {
    margin-top: 16px;
  }
}

.segmentControlHolder {
  position: sticky;
  top: 0;
  background-color: Color.$snow;
  width: calc(100% + 2 * Var.$modal-body-padding);
  margin-left: -1 * Var.$modal-body-padding;
  padding: 8px Var.$modal-body-padding 12px;
  z-index: 2;

  &::before {
    transition: box-shadow 300ms;
    box-shadow: none;
    content: '';
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: -24px;
    right: -24px;
    height: 1px;
  }

  @include Media.medium {
    width: calc(100% + 2 * Var.$modal-body-desktop-padding);
    margin-left: -1 * Var.$modal-body-desktop-padding;
    padding: 8px Var.$modal-body-desktop-padding 12px;
  }

}

.bottomBorder::before {
  box-shadow: 0 0 5px rgb(0, 0, 0);
}

.stickyMask {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  background-color: Color.$snow;
}

.segmentControl {
  position: relative;
  z-index: 1;
}

.paymentAccount {
  position: relative;
}

    