
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.link {
  font-weight: bold;
  color: Color.$surfie;
  text-decoration: none;
  border-radius: 2px;
  outline-offset: 2px;

  &,
  &:visited,
  &:active {
    color: Color.$surfie;
  }

  &:hover {
    color: Color.$surfie-hover;
  }
}

.linkOutIcon {
  margin-left: 4px;
}

    