
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.fields {
  & + & {
    margin-top: 16px;
  }

  p + p {
    margin-top: 8px;
  }
}

.title {
  color: Color.$slate;
  margin-bottom: 4px;
}


    