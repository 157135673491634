
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.toast {
  background-color: Color.$eclipse;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 13px 20px;
  color: Color.$snow;
  z-index: Var.$toast-zindex;
  transition: margin 60ms ease-in;
  display: inline-flex;

  &.warning {
    background-color: Color.$punch;
    font-weight: bold;
  }

  .icon {
    fill: Color.$snow;
    margin-right: 8px;
  }
}

.enter {
  opacity: 0;
}

.enterActive {
  opacity: 1;
}

.exit {
  opacity: 1;
}

.exitActive {
  opacity: 0;
  bottom: 0;
  transition: all 300ms;
}

.exitDone {
  opacity: 0;
}

    