
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.selector {
  display: flex;
  padding: 16px 0;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid transparent;
}

.text {
  display: flex;
  align-items: baseline;

  * + * {
    margin-left: 4px;
  }
}

    