
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

$background-color: Color.$snow;

.thread {
  max-width: 100%;
  border: 1px solid Color.$smoke;
  padding: 16px;
  border-radius: 4px;
}

.messages {
  margin-top: 16px;
  display: flex;
  flex-direction: column;


  @include Media.medium {
    &.system {
      margin-left: 25%;
      align-items: flex-start;
    }

    &.user {
      margin-right: 25%;
      align-items: flex-end;
    }
  }

  &.os {
    align-items: center;
  }
}

.message {
  display: flex;
  align-items: flex-end;
  gap: 8px;
}

.messageContent {
  position: relative;
  border-radius: 16px;
  padding: 8px 16px;
  margin-top: 4px;
  margin-bottom: 4px;
  display: inline-block;
  white-space: pre-line;
  word-break: break-word;
  user-select: none;
}

.system .messageContent {
  background-color: Color.$smoke;

  &:last-child::before {
    content: "";
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: -7px;
    height: 20px;
    width: 20px;
    background: Color.$smoke;
    border-bottom-right-radius: 15px;
  }

  &:last-of-type::after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: -10px;
    width: 10px;
    height: 20px;
    background: $background-color;
    border-bottom-right-radius: 10px;
  }
}

.user .messageContent {
  background-color: Color.$sea;
  color: Color.$snow;

  &:last-child::before {
    content: "";
    position: absolute;
    z-index: 0;
    bottom: 0;
    right: -7px;
    height: 20px;
    width: 20px;
    background: Color.$sea;
    border-bottom-left-radius: 15px;
  }

  &:last-of-type::after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: -10px;
    width: 10px;
    height: 20px;
    background: $background-color;
    border-bottom-left-radius: 10px;
  }
}

.context {
  text-align: center;
  display: inline-block;
  color: Color.$slate;
  font-size: 75%;
}
    