
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.container {
  border: 1px solid Color.$eclipse;
  padding: 16px;
  display: flex;
  align-items: center;
  border-radius: 4px;

  &.warning {
    background: fade-out(Color.$jaffa, 0.9);
    border-color: fade-out(Color.$jaffa, 0.4);

    .graphic {
      color: Color.$jaffa;
    }
  }

  &.error {
    background: fade-out(Color.$punch, 0.9);
    border-color: fade-out(Color.$punch, 0.4);

    .graphic {
      color: Color.$punch;
    }
  }

  &.neutral {
    background: Color.$cloud;
    border-color: Color.$smoke;

    .graphic {
      color: Color.$slate;
    }
  }

  &.small {
    padding: 8px;

    .text {
      margin-left: 8px;
    }
  }
}

.graphic {
  display: flex;
  justify-content: center;
}

.text {
  margin-left: 16px;
  min-width: 0;

  > p {
    margin: 0;
  }
}

    