
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.toggle:not(:first-child) {
  margin-top: 16px;
}

.toggle {
  display: flex;
  align-items: center;
}

.infoIcon {
  vertical-align: text-bottom;
}

.helperText {
  padding-left: 32px;
}

    