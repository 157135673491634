
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'


.text {
  line-height: 22px;
  margin-bottom: 12px;
}

.circle {
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  display: inline-flex;

  .icon {
    color: Color.$snow;
    height: 24px;
    width: 24px;
  }
}

.contactItem {
  margin-top: 8px;
  margin-bottom: 8px;
  flex-direction: row;
  align-items: center;
  display: flex;
}

.address {
  font-weight: bold;
  line-height: 22px;
}

.content {
  padding: 0 24px 32px;
}

    