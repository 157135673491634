$font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif;

$sidenav-width: 240px;
$mobilebottomnav-height: 48px;
$desktop-content-max-width: 1680px;
$hover-inset: 4px;
$screen-frame-padding-desktop: 32px;
$subcontent-max-width: 560px;

$nav-zindex: 10;
$modal-zindex: $nav-zindex + 1;
$dropdown-zindex: $modal-zindex + 1;
$select-zindex: $dropdown-zindex + 1;
$toast-zindex: $select-zindex + 1;
$tooltip-zindex: $toast-zindex + 1;
$server-indicator-zindex: $tooltip-zindex + 1;

$modal-body-padding: 16px;
$modal-body-desktop-padding: 24px;
