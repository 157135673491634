
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.tab {
  display: flex;
  align-items: center;
  flex-direction: column;
  min-width: 64px;
  width: 100%;

  text-decoration: none;
  font-size: 10px;
  font-weight: 600;
  color: Color.$eclipse;
  border-radius: 4px;

  @include Media.lt-medium {
    justify-content: center;
  }

  @include Media.medium {
    flex-direction: row;
    align-items: center;
    width: auto;
    max-width: none;
    font-size: 14px;
    font-weight: normal;
    padding: 8px;
    margin: 4px 0;

    &:hover {
      background-color: Color.$smoke;
    }
  }

  &:active {
    background-color: Color.$gainsboro;
  }

  &.active {
    color: Color.$surfie;
    font-weight: 600;
    background-color: Color.$surfie-lightest;
    mix-blend-mode: multiply;
  }

  .label {
    min-width: 0;
    width: 100%;
    text-align: center;

    @include Mixin.text-ellipsis();

    @include Media.medium {
      width: auto;
      text-align: left;
    }
  }
}

.icon {
  position: relative;

  @include Media.medium {
    margin-right: 12px;
  }

  &,
  svg {
    height: 24px;
    width: 24px;
  }

  &.indicator {
    svg {
      mask-image: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMjQgMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPGRlZnM+CiAgICA8bWFzayBpZD0iaG9sZSI+CiAgICAgIDxyZWN0IHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9IndoaXRlIi8+CiAgICAgIDxjaXJjbGUgcj0iNiIgY3g9IjIwIiBjeT0iMjAiIGZpbGw9ImJsYWNrIi8+CiAgICA8L21hc2s+CiAgPC9kZWZzPgogIDxyZWN0IHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIG1hc2s9InVybCgjaG9sZSkiLz4KPC9zdmc+Cg==');
      mask-size: 100% 100%;
      mask-clip: border-box;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      width: 8px;
      height: 8px;
      background: Color.$jaffa;
      border-radius: 50%;
    }
  }
}

.badge {
  margin-left: 8px;

  @include Media.lt-medium {
    display: none;
  }
}

.popoverContainer {
  z-index: Var.$dropdown-zindex;
}

.popover {
  width: 224px;
  background-color: Color.$cloud;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 8px 0;
}

.popover .tab {
  padding: 16px;
  width: 100%;
  max-width: 100%;
  justify-content: flex-start;
  flex-direction: row;
  gap: 8px;
  font-size: 14px;
  color: Color.$surfie;

  .label {
    text-align: left;
  }
}
    