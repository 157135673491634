
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.fixedFooter {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;

  &::after {
    content: '';
    display: block;
    height: env(safe-area-inset-bottom);
    background-color: Color.$cloud;
  }

  @include Media.medium {
    left: 0;
    bottom: 0;
    right: 0;
  }
}

.toast {
  position: absolute;
  left: 16px;
  right: 16px;
  transform: translateY(calc(-100% - 20px));

  @include Media.medium {
    left: 32px;
    right: 32px;
  }
}
    