
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.shadow {
  box-shadow: 0 2px 6px rgba(Color.$shadow, 0.2);
}

.iconContainer {
  background-color: Color.$surfie;
}

    