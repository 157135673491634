
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'


.textAlign-right {
  justify-self: flex-end;
  text-align: right;
}

.textAlign-center {
  justify-self: center;
  text-align: center;
}

.pastDue {
  color: Color.$punch;
}

.placeholder {
  color: Color.$slate;
}

.noWrap {
  @include Mixin.text-ellipsis();
}

.checkbox {
  // negative margin to keep cell at 40px
  margin: -1px 0;
}

.selection {
  padding-right: 0;
}
    