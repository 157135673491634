
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.attachmentList {
  margin: 0;

  li {
    list-style-type: '•';
    padding-left: 6px;
  }
}

.attachmentsInput {
  max-width: 560px;
}

    