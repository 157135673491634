
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.badgePile {
  position: relative;
  display: flex;
  z-index: 1;

  >div:first-child {
    flex-shrink: 0;
    direction: ltr;
  }

  >div {
    flex-shrink: 99;
    flex-basis: max-content;
    overflow: hidden;
    direction: rtl;
    white-space: nowrap;
    position: relative;
    font-size: 0;
  }

  >div:not(:last-child){
    border-right: 2px solid Color.$snow;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 2px;
      background-color: Color.$snow;
      z-index: -1;
    }
  }

  >div:not(:first-child) {
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.popoverContainer {
  z-index: Var.$tooltip-zindex;
}

.popover {
  @include Mixin.shadow();
  padding: 8px;
  border-radius: 4px;
  background: Color.$snow;
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-width: 150px;
}

    