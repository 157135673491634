
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.description {
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  svg {
    color: Color.$surfie;
    margin-right: 8px;
  }

  &.disabled {
    svg {
      color: Color.$punch;
    }
  }
}

    