
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.successState {
  h2 {
    margin: 32px 0 16px;
  }

  p {
    margin: 16px 0 24px;
  }
}

.checkmark {
  width: 96px;
  height: 96px;
  border-radius: 100%;
  background-color: Color.$surfie;
  color: Color.$snow;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 32px;
}

    