
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.button {
  padding: 0;
  border: 0;
  background: transparent;
  color: Color.$eclipse;
  border-radius: 2px;
  width: 24px;
  height: 24px;
}

    